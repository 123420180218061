import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeGetMarket } from 'Sportsbook/markets/selector';

export default (WrappedComponent) => {
  const makeMapStateToProps = () => {
    const getMarket = makeGetMarket();

    return (state, ownProps) => ({
      market: getMarket(state, ownProps),
    });
  };

  const WithMarket = (props) => {
    const { market } = props;

    return (
      market ? <WrappedComponent {...props} /> : null
    );
  };

  WithMarket.propTypes = {
    market: PropTypes.instanceOf(Object).isRequired,
  };

  WithMarket.displayName = 'withMarket';

  return connect(makeMapStateToProps)(WithMarket);
};
