import PropTypes from 'prop-types';
import { noop } from 'underscore';
import { t } from 'bv-i18n';

import formatWeight from 'Sportsbook/helpers/format_weight';
import Silk from '../silk';
import Rating from './rating';

const RunnerDetail = ({
  runnerName,
  drawNumber,
  raceNumber,
  jockey,
  trainer,
  form,
  runnerWeight,
  starRating,
  silk,
  displayWeight,
  onClick,
  isFavourite,
}) => (
  <>
    <Silk silk={silk} />
    <span className="racecard-outcome-number">{raceNumber > 0 ? raceNumber : ''}</span>
    <div className="racecard-outcome-info" onClick={onClick}>
      <p>
        <h3>{runnerName}</h3>
        {drawNumber > 0 && ` (${drawNumber})`}
      </p>
      { trainer && <p>{`${t('javascript.race_overview.trainer')}: ${trainer}`}</p> }
      { jockey && <p>{`${t('javascript.race_overview.jockey')}: ${jockey}`}</p> }
      { !isFavourite && <p>{`${t('javascript.race_overview.performances')}: ${form || '-'}`}</p> }
      { displayWeight && runnerWeight && <p>{`${t('javascript.race_overview.weight')}: ${formatWeight(runnerWeight)}`}</p> }
      { starRating && <Rating starRating={starRating} /> }
      { noop !== onClick && <span className="racecard-outcome-more-info">{t('javascript.race_overview.more_info')}</span> }
    </div>
  </>
);

RunnerDetail.propTypes = {
  runnerName: PropTypes.string.isRequired,
  drawNumber: PropTypes.string.isRequired,
  raceNumber: PropTypes.string.isRequired,
  jockey: PropTypes.string.isRequired,
  trainer: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  runnerWeight: PropTypes.string.isRequired,
  starRating: PropTypes.number.isRequired,
  silk: PropTypes.string.isRequired,
  displayWeight: PropTypes.bool,
  onClick: PropTypes.func,
  isFavourite: PropTypes.bool,
};

RunnerDetail.defaultProps = {
  displayWeight: false,
  onClick: noop,
  isFavourite: false,
};

export default RunnerDetail;
