import { updateMarket } from '../duck';

// We're gonna worry about market status updates (suspensions) and timestamps atm
export default (err, { body: event }) => {
  const { store } = window.reduxState;

  (event.u || []).forEach(({
    id, ptd, st, timestamp, next, cur, mblindex,
  }) => {
    store.dispatch(
      updateMarket(
        id,
        {
          pltD: ptd,
          st,
          next,
          current: cur,
          mbl: mblindex,
          timestamp,
        },
      ),
    );
  });
};
