import { t } from 'bv-i18n';

import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';

import WithSelectors from '../with_selectors/entry';

const splitFn = (desc) => desc.match(/(.*)\s[/|-]\s(.*)/)?.slice(1, 3);

const GenericTwoSelectors = (props) => (
  <WithSelectors
    {...props}
    splitFn={splitFn}
    nSelects={2}
    titles={[t('select_player'), t('select_score')]}
  />
);

export default withBackboneMarket(withMarket(GenericTwoSelectors));
