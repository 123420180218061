import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { arrayMove } from 'bv';
import { t } from 'bv-i18n';
import { OPEN_BETS_TAB_ID } from 'MarketsV2/helpers/constants';
import { useEventBetsCount } from 'SharedComponents/my_bets/hooks';
import MarketTab from './market_tabs/market_tab';
import MarketTabs from './market_tabs/market_tabs';
import MoreMarkets from './more_markets';

const MarketPicker = ({
  marketsIds,
  activeMarketId,
  setActiveMarketId,
  eventId,
}) => {
  const eventBetsCount = useEventBetsCount(eventId);
  const inOpenBetsTab = activeMarketId === OPEN_BETS_TAB_ID;

  // We calculate the number of tabs to display (before the three dots)
  // given the width of the screen (and also considering the buttons have
  // a fixed width specified)
  const tabsLength = (window.innerWidth < 375 ? 2 : 3) - (eventBetsCount ? 1 : 0);

  // If the active market is not visible on the Tabs we reorder the markets
  // and place the active market in the last position which is visible in the Tabs
  const reorderedMarketsIds = inOpenBetsTab || marketsIds.indexOf(activeMarketId) < tabsLength
    ? marketsIds
    : arrayMove(marketsIds, activeMarketId, tabsLength - 1);

  const tabsMarketsIds = reorderedMarketsIds.slice(0, tabsLength);
  const selectorMarketsIds = reorderedMarketsIds.slice(tabsLength);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!eventBetsCount && activeMarketId === OPEN_BETS_TAB_ID) {
        // open active market(tab) after cashing out
        setActiveMarketId(marketsIds[0]);
      }
    }, 2200); // wait for the cash out animation to finish

    return () => clearTimeout(timer);
  }, [activeMarketId, eventBetsCount]);

  return (
    (tabsMarketsIds.length > 1 || eventBetsCount > 0) && (
      <div className="selectors-with-cta-wrapper">
        <div className="selectors-with-cta">
          {eventBetsCount > 0 && (
            <MarketTab
              market={{ id: OPEN_BETS_TAB_ID, des: t('javascript.cashout.event_level.open_bets') }}
              isActive={inOpenBetsTab}
              setActiveMarketId={setActiveMarketId}
              badge={eventBetsCount}
            />
          )}
          <MarketTabs
            marketsIds={tabsMarketsIds}
            activeMarketId={activeMarketId}
            setActiveMarketId={setActiveMarketId}
          />
        </div>
        {selectorMarketsIds.length > 0 && (
          <MoreMarkets
            marketsIds={selectorMarketsIds}
            setActiveMarketId={setActiveMarketId}
          />
        )}
      </div>
    )
  );
};

MarketPicker.propTypes = {
  marketsIds: PropTypes.instanceOf(Array).isRequired,
  activeMarketId: PropTypes.number.isRequired,
  setActiveMarketId: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default MarketPicker;
