import PropTypes from 'prop-types';

const Title = ({ title, superScript }) => (
  <div className="racecard-modal__runner-details--title">
    <span>
      {title}
      {' '}
      <sup>{superScript}</sup>
    </span>
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  superScript: PropTypes.number.isRequired,
};

export default Title;
