import { addMiddleware } from 'redux-dynamic-middlewares';

import betslipReducer from './betslip/duck';
import marketsReducer from './markets/duck';
import outcomesReducer from './outcomes/duck';
import eventsReducer from './events/duck';
import optionsReducer, { toggle } from './options/duck';
import { Redux as EssentialScoresRedux } from './essential_scores';
import { Redux as SuspensionReasonsRedux } from './suspension_reasons';

import betslipMiddleware from './betslip/middleware';
import outcomesMiddleware from './outcomes/middleware';
import marketsMiddleware from './markets/middleware';

const { addReducers, addActionDispatchers } = window.reduxState;

// Add the reducers needed for the sportsbook
addReducers({
  betslip: betslipReducer,
  markets: marketsReducer,
  outcomes: outcomesReducer,
  events: eventsReducer,
  sportsbookOptions: optionsReducer,
  'sportsbook/essentialScores': EssentialScoresRedux.reducer,
  'sportsbook/suspensionReasons': SuspensionReasonsRedux.reducer,
});

// Add the middleware needed for the sportsbook
addMiddleware(
  betslipMiddleware,
  outcomesMiddleware,
  marketsMiddleware,
  EssentialScoresRedux.middleware,
);

// Expose the action dispatcher to toggle an option from a no React Component
addActionDispatchers('sportsbookOptions', { toggle });
