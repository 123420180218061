/* eslint import/prefer-default-export: off */
import { createSelector } from 'reselect';
import {
  compact, flatten, intersection, negate,
} from 'underscore';
import { isMarketClosed } from 'Sportsbook/helpers/market_status';
import { getOutcomes } from 'Sportsbook/outcomes/selector';

const getMarkets = (state) => state.markets;
const getMarketId = (_, props) => props.marketId || props.id;
const getOutcomeId = (_, props) => parseInt(props.outcomeId, 10);
const getOutcomeIds = (_, props) => props.outcomeIds.map((id) => parseInt(id, 10));
const getEventId = (_, props) => props.eventId;

const makeGetEventOutcomes = () => createSelector(
  [getMarkets, getOutcomes, getEventId],
  (markets, outcomes, eventId) => {
    const eventMarkets = Object.values(markets || [])
      .filter((m) => [m.eId, m.eid].includes(eventId));
    const outcomeIds = flatten(eventMarkets.map((m) => m.o || []));
    return compact(outcomeIds.map((outcomeId) => outcomes[outcomeId]));
  },
);

const makeGetMarket = () => createSelector(
  getMarketId,
  getMarkets,
  (marketId, markets) => markets[marketId],
);

const makeGetMarketOutcomes = () => createSelector(
  makeGetMarket(),
  getOutcomes,
  (market, outcomes) => (market ? market.o.map((outcomeId) => outcomes[outcomeId]) : []),
);

const makeGetMarketFilteredOutcomes = () => createSelector(
  makeGetMarketOutcomes(),
  (outcomes) => outcomes.filter((o) => o.enabled && !(o.desc === '<favourite>')),
);

const makeGetMarketVisibleOutcomes = () => createSelector(
  makeGetMarketOutcomes(),
  (outcomes) => outcomes.filter((o) => !(o.h || o.hidden)),
);

const getMarketTypeId = (_, props) => props.marketTypeId;

const makeGetMarketsByMarketTypeId = () => createSelector(
  [getMarkets, getMarketTypeId],
  (markets, marketTypeId) => Object.values(markets)
    .filter((market) => market.mtId === marketTypeId),
);

const getOpponentId = (_, props) => props.opponentId;

const makeGetLTOMarketId = () => createSelector(
  [makeGetMarketsByMarketTypeId(), getOutcomes, getOpponentId],
  (markets, outcomes, opponentId) => {
    const ltoMarket = markets
      .filter(negate(isMarketClosed))
      .find((market) => outcomes[market.o[0]].opponentId === opponentId);

    return ltoMarket && ltoMarket.id;
  },
);

const makeGetMarketByOutcomeId = () => createSelector(
  [getMarkets, getOutcomeId],
  (markets, outcomeId) => (
    Object.values(markets || []).find((market) => (market.o || []).includes(outcomeId))
  ),
);

const makeGetMarketsByOutcomeIds = () => createSelector(
  [getMarkets, getOutcomeIds],
  (markets, outcomeIds) => (
    Object.values(markets || []).filter((market) => (intersection(market.o, outcomeIds).length > 0))
  ),
);

export {
  getMarkets,
  makeGetMarket,
  makeGetMarketOutcomes,
  makeGetMarketFilteredOutcomes,
  makeGetMarketVisibleOutcomes,
  makeGetLTOMarketId,
  makeGetMarketByOutcomeId,
  makeGetMarketsByOutcomeIds,
  makeGetEventOutcomes,
};
