/* eslint import/no-named-as-default:off */

import Default from './default/entry';
import MatchBetting from './match_betting/entry';
import PriceItUp from './price_it_up/entry';
import TwoColumns from './two_columns/entry';
import AsianHandicap from './asian_handicap/entry';
import GenericTwoSelectors from './generic_two_selectors/entry';
import OneSelect from './one_select/entry';
import TwoSelectHalfTimeFullTime from './two_select_half_time_full_time/entry';
import GenericThreeSelectors from './generic_three_selectors/entry';
import Scorecast from './scorecast/entry';
import RacingDefault from './racing_default/entry';
import OutrightLTO from './outright_lto/entry';

const defaultMapping = {
  AsianHandicap,
  Default,
  GenericThreeSelectors,
  GenericTwoSelectors,
  MatchBetting,
  OneSelect,
  OutrightLengthenTheOdds: OutrightLTO,
  PriceItUp,
  Scorecast,
  TwoColumns,
  TwoSelectHalfTimeFullTime,
};

const mappings = [
  364, // Horse Racing
  1000, // Greyhounds
  16334710, // Virtual Horse Racing
  16334810, // Virtual Dog Racing
  16335010, // Virtual Motor Racing
  16334910, // Virtual Speedway
  144515010, // Virtual Cycling
].reduce((accum, sportId) => ({
  ...accum,
  [sportId]: {
    ...defaultMapping,
    Default: RacingDefault,
  },
}), { default: defaultMapping });

// sportId is actually the sport event path id (240 for football, 364 for HR ...)
export default (sportId) => mappings[sportId] || mappings.default;
