import PropTypes from 'prop-types';
import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';
import Outcome from 'Sportsbook/outcomes/views/default/entry';

export const Default = ({ market }) => (
  market.o.map((outcomeId) => (
    <div className="outcomes-row">
      <Outcome id={outcomeId} disabled={!!market.disabled} />
    </div>
  ))
);

Default.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.number.isRequired,
    o: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};

export default withBackboneMarket(withMarket(Default));
