require('expose-loader?Sportsbook!../../src/app/sportsbook');
// Expose the content
require('expose-loader?SportsbookHelpers!../../src/app/sportsbook/helpers');
require('expose-loader?SportsbookHooks!../../src/app/sportsbook/hooks');
require('expose-loader?SportsbookOutcomesDuck!../../src/app/sportsbook/outcomes/duck');
require('expose-loader?SportsbookWithOutcome!../../src/app/sportsbook/outcomes/with_outcome');
require('expose-loader?SportsbookOutcomesSelector!../../src/app/sportsbook/outcomes/selector');
require('expose-loader?SportsbookMarketsDuck!../../src/app/sportsbook/markets/duck');
require('expose-loader?SportsbookMarketsSelector!../../src/app/sportsbook/markets/selector');
require('expose-loader?SportsbookBetslipDuck!../../src/app/sportsbook/betslip/duck');
require('expose-loader?SportsbookBetslipSelector!../../src/app/sportsbook/betslip/selector');
require('expose-loader?SportsbookBetslipThunks!../../src/app/sportsbook/betslip/thunks');
require('expose-loader?SportsbookBetslipDataLayer!../../src/app/sportsbook/betslip/data_layer');
require('expose-loader?SportsbookBetslipComponents!../../src/app/sportsbook/betslip/components');
require('expose-loader?SportsbookComponents!../../src/app/sportsbook/components');
require('expose-loader?SportsbookOptionsDuck!../../src/app/sportsbook/options/duck');
require('expose-loader?SportsbookMarket!../../src/app/sportsbook/markets/market_card');
require('expose-loader?SportsbookMarketsV2!../../src/app/sportsbook/markets_v2');
// Event related ----------
require('expose-loader?SportsbookEventsDuck!../../src/app/sportsbook/events/duck');
require('expose-loader?SportsbookEventsSelector!../../src/app/sportsbook/events/selector');
require('expose-loader?SportsbookEventHooks!../../src/app/sportsbook/events/hooks');
// MarketView related ----------
require('expose-loader?SportsbookMarketViews!../../src/app/sportsbook/markets/views/market_views');
// ------------------------------
require('expose-loader?SportsbookEssentialScores!../../src/app/sportsbook/essential_scores');
require('expose-loader?SportsbookSuspensionReasons!../../src/app/sportsbook/suspension_reasons');
// Sports Tree
require('expose-loader?SportsbookSportsTree!../../src/app/sportsbook/sports_tree');
// Competitions
require('expose-loader?SportsbookCompetitions!../../src/app/sportsbook/competitions');
// Normalizers and entities
require('expose-loader?SportsbookNormalizers!../../src/app/sportsbook/normalizers');
require('expose-loader?SportsbookNormalizerEntities!../../src/app/sportsbook/normalizers/entities');
// Services
require('expose-loader?SportsbookServices!../../src/app/sportsbook/services');
