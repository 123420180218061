/* eslint arrow-body-style: off, no-unused-vars: off */
import {
  compact, groupBy, property, sortBy, uniq,
} from 'underscore';
import { locale } from 'bv-i18n';

const formattedDay = (timeString) => new Date(timeString).toLocaleDateString(locale(), {
  weekday: 'short',
  day: 'numeric',
  year: 'numeric',
  month: 'long',
});

const daysFor = (sportsTree) => Object.entries(groupBy(
  sortBy(sportsTree.flatMap((item) => item.children), (event) => new Date(event.startTime)),
  (event) => formattedDay(event.startTime),
)).map(([day, children]) => ({
  id: day,
  title: day,
  eventLabels: uniq(compact(children.flatMap(property('labels')))),
  expanded: false,
  hidden: true,
}));

const groupsByDayFor = (sportsTree) => groupBy(
  sportsTree.reduce((acc, group) => {
    const groupDays = groupBy(
      group.children,
      (event) => formattedDay(event.startTime),
    );

    return [
      ...acc,
      ...Object.keys(groupDays).map((day) => ({
        ...group,
        day,
        children: group.children
          .filter((event) => day === formattedDay(event.startTime))
          .map((event) => event.id),
      })),
    ];
  }, []),
  (group) => group.day,
);

const eventsFor = (sportsTree) => sportsTree.flatMap((item) => item.children)
  .reduce((acc, event) => ({
    ...acc,
    [event.id]: event,
  }), {});

export default (sportsTree) => ({
  cards: daysFor(sportsTree),
  groupsByCard: groupsByDayFor(sportsTree),
  events: eventsFor(sportsTree),
});
