import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOutcomes, removeOutcomes } from 'Sportsbook/outcomes/duck';
import { addMarkets, removeMarkets } from 'Sportsbook/markets/duck';

import adapter from './adapter';

export default (WrappedComponent) => {
  const mapStateToProps = () => ({
    // ...
  });

  const mapDispatchToProps = (dispatch) => ({
    dispatchAddOutcomes: (outcomes) => dispatch(addOutcomes(outcomes)),
    dispatchRemoveOutcomes: (outcomes) => dispatch(removeOutcomes(outcomes.map((o) => o.id))),
    dispatchAddMarkets: (markets) => dispatch(addMarkets(markets)),
    dispatchRemoveMarkets: (markets) => dispatch(removeMarkets(markets.map((m) => m.id))),
  });

  class WithBackboneMarket extends PureComponent {
    constructor(props) {
      super(props);

      this.state = adapter(props.backboneMarket);
    }

    componentDidMount() {
      const { outcomes, market } = this.state;
      const { dispatchAddOutcomes, dispatchAddMarkets } = this.props;

      dispatchAddOutcomes(outcomes);
      dispatchAddMarkets([market]);
    }

    componentWillUnmount() {
      const { outcomes, market } = this.state;
      const { dispatchRemoveOutcomes, dispatchRemoveMarkets } = this.props;

      dispatchRemoveOutcomes(outcomes);
      dispatchRemoveMarkets([market]);
    }

    render() {
      const { market, options } = this.state;

      return (
        <WrappedComponent
          id={market.id}
          options={options}
        />
      );
    }
  }

  WithBackboneMarket.propTypes = {
    backboneMarket: PropTypes.instanceOf(Object).isRequired,
    dispatchAddOutcomes: PropTypes.func.isRequired,
    dispatchRemoveOutcomes: PropTypes.func.isRequired,
    dispatchAddMarkets: PropTypes.func.isRequired,
    dispatchRemoveMarkets: PropTypes.func.isRequired,
  };

  WithBackboneMarket.displayName = 'withBackboneMarket';

  return connect(mapStateToProps, mapDispatchToProps)(WithBackboneMarket);
};
