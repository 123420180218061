import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'underscore';

import { useToggle } from 'bv-hooks';
import Runner from 'Sportsbook/components/runner/runner';
import RunnerPopupContainer from 'Sportsbook/components/runner/runner_popup/runner_popup_container';
import withOutcome from 'Sportsbook/outcomes/with_outcome';
import withRunner from 'Sportsbook/outcomes/with_runner';
import { makeGetLTOMarketId } from 'Sportsbook/markets/selector';

import LengthenTheOdds from './lengthen_the_odds/entry';

const showLTO = (outcome) => outcome.enabled && outcome.prd > 0;

const onClick = (hasRunnerPopup, toggleDisplayRunnerPopup) => (hasRunnerPopup
  ? toggleDisplayRunnerPopup
  : noop);

export const Outcome = (props) => {
  const [displayRunnerPopup, toggleDisplayRunnerPopup] = useToggle(false);
  const {
    outcome, eventId, outcomeOptions, runner,
  } = props;

  // TODO: Extract to redux/container/view when entry.jsx is removed
  // To be done when not used market views are removed
  // And withBackbone HOCs are removed.
  const getLTOMarketId = useMemo(makeGetLTOMarketId, []);
  const ltoMarketId = useSelector((state) => (
    getLTOMarketId(state, {
      opponentId: outcome.opponentId,
      marketTypeId: outcomeOptions.marketTypeId,
    })
  ));

  return (
    <Runner
      {...props}
      displayWeight={!runner}
      onClick={onClick(!!runner, toggleDisplayRunnerPopup)}
    >
      {showLTO(outcome) && ltoMarketId ? (
        <LengthenTheOdds
          parentOutcomePrice={outcome.prd}
          LTOPriceRange={outcomeOptions.LTOPriceRange}
          marketId={ltoMarketId}
        />
      ) : <hr />}
      {
        displayRunnerPopup
        && (
        <RunnerPopupContainer
          id={outcome.id}
          marketId={outcome.mId}
          eventId={eventId}
          allOutcomeIds={outcomeOptions.allOutcomeIds}
          onClick={toggleDisplayRunnerPopup}
        />
        )
      }
    </Runner>
  );
};

Outcome.propTypes = {
  eventId: PropTypes.number.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  outcomeOptions: PropTypes.instanceOf(Object).isRequired,
  runner: PropTypes.instanceOf(Object).isRequired,
};

export default withOutcome(withRunner(Outcome));
