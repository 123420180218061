import { compose, property, sortBy } from 'underscore';

const filter = (selectsOptions, indexedOutcomesValues, selectedValues) => {
  const outcomesValues = Object.values(indexedOutcomesValues);

  return selectsOptions.map((options, index) => (
    options.filter((value) => {
      // A value is valid if the combination of it and current selects is possible
      const possibleSelection = Object.assign([], selectedValues, { [index]: value });

      return outcomesValues.some((outcomeValues) => (
        possibleSelection.every((selection, pairIndex) => (
          !selection || selection === outcomeValues[pairIndex]
        ))
      ));
    })
  ));
};

const sort = (scoreOptions) => Object.keys(scoreOptions).reduce((accum, key) => ({
  ...accum,
  [key]: sortBy(scoreOptions[key], property('label')),
}), {});

const groupParser = (selectOptions) => selectOptions.reduce((accum, value) => {
  const match = value.match(/(.*) (\d+)\s*[:|-]\s*(\d+)/);

  const groupKey = match[1];
  const label = `${match[2]}-${match[3]}`;

  return {
    ...accum,
    [groupKey]: [...accum[groupKey] || [], { value, label }],
  };
}, {});

const nonSupportedParser = (selectOptions) => selectOptions.reduce((accum, value) => ([
  ...accum,
  { value, label: value },
]), []);

const supportGrouping = (selectOptions) => selectOptions
  && selectOptions[0].match(/(.*) (\d+)\s*[:|-]\s*(\d+)/);

const group = (selectOptions) => (
  supportGrouping(selectOptions)
    ? sort(groupParser(selectOptions))
    : nonSupportedParser(selectOptions)
);

const present = (selectsOptions) => Object.assign([], selectsOptions, {
  1: group(selectsOptions[1]),
});

export default compose(present, filter);
