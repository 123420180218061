import PropTypes from 'prop-types';

import getSilkClassName from '../helpers/get_silk_class_name';

const CssSilk = ({
  raceNumber,
  sportEventPathId,
}) => {
  const className = getSilkClassName(sportEventPathId, raceNumber);

  if (!className) return null;
  return <span className={className} />;
};

CssSilk.propTypes = {
  raceNumber: PropTypes.number.isRequired,
  sportEventPathId: PropTypes.number.isRequired,
};

export default CssSilk;
