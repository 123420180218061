import PropTypes from 'prop-types';

import { ContentIcon } from 'bv-components';
import withOutcome from 'Sportsbook/outcomes/with_outcome';

const Outcome = ({ outcome, removeFromBetslip }) => (
  <li className="flex">
    <span className="flex--grow">{outcome.desc}</span>
    <span className="flex">
      {outcome.formattedPrice}
      <ContentIcon small remove onClick={removeFromBetslip} />
    </span>
  </li>
);

Outcome.propTypes = {
  removeFromBetslip: PropTypes.func.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
};

export default withOutcome(Outcome);
