import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isSmall } from 'bv-helpers/screen';
import { RenderInBody, Icon } from 'bv-components';

import Runner from '../runner';
import Stats from './stats';

const RunnerPopup = ({
  id, filteredOutcomeIds, eventId, onClick,
}) => {
  // Ref for the current outcome into the list, will be used to scroll into it
  const currentOutcomeRef = useRef(null);
  // Side Effects regarding scrolling to current outcome and locking background scroll
  useEffect(() => {
    // scroll to the current outcome
    currentOutcomeRef
      .current
      .scrollIntoView(true);
    // lock / unlock the scroll behind the modal
    document.documentElement.classList.add('body-scroll-lock');
    return () => document.documentElement.classList.remove('body-scroll-lock');
  }, []);
  // ----------------------------------------------------------------

  return (
    <RenderInBody>
      <div
        className="racecard-modal__wrapper modal_wrapper"
        {...(isSmall() ? { onClick } : {})}
      >
        <div className="has-scroll-bars">
          <div className="racecard-modal__wrapper-list">
            <span className="close-icon" onClick={onClick}>
              <Icon id="close" inverted className="racecard-modal__close-button" />
            </span>
            {
            filteredOutcomeIds.map((outcomeId) => (
              <div className="racecard-modal racecard-runner">
                <span id={`racecard-runner-${outcomeId}`} className="racecard-runner__anchor" ref={id === outcomeId ? currentOutcomeRef : null} />
                <Runner id={outcomeId} eventId={eventId} extendedSmartStat />
                <Stats id={outcomeId} eventId={eventId} />
              </div>
            ))
          }
          </div>
        </div>
      </div>
    </RenderInBody>
  );
};

RunnerPopup.propTypes = {
  id: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  filteredOutcomeIds: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RunnerPopup;
