/* eslint no-unused-vars: off */
import { connect } from 'react-redux';
import { makeGetMarketFilteredOutcomes } from 'Sportsbook/markets/selector';
import { property } from 'underscore';
import RunnerPopup from './runner_popup';

const mapStateToProps = () => {
  const getMarketFilteredOutcomes = makeGetMarketFilteredOutcomes();

  return (state, ownProps) => ({
    filteredOutcomeIds: getMarketFilteredOutcomes(state, ownProps).map(property('id')),
  });
};

export default connect(mapStateToProps)(RunnerPopup);
