const description = (event) => event.description || event.desc;
const sportId = (event) => event.sportId || event.sport_id;
const startTime = (event) => event.startTime || event.time;
const opponentADescription = (event) => event.opponentADescription || event.oppADesc;
const opponentBDescription = (event) => event.opponentBDescription || event.oppBDesc;

export default (event) => ({
  ...event,
  description: description(event),
  opponentADescription: opponentADescription(event),
  opponentBDescription: opponentBDescription(event),
  sportId: sportId(event),
  startTime: startTime(event),
});
