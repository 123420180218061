import PropTypes from 'prop-types';
import { Button } from 'bv-components';

const MarketTab = ({
  market, isActive, setActiveMarketId, badge,
}) => (
  <Button
    active={isActive}
    onClick={() => setActiveMarketId(market.id)}
    noClass
    className="bvs-button-chip"
    badge={badge}
  >
    {market.des}
  </Button>
);

MarketTab.propTypes = {
  market: PropTypes.instanceOf(Object).isRequired,
  isActive: PropTypes.bool.isRequired,
  setActiveMarketId: PropTypes.func.isRequired,
  badge: PropTypes.number,
};

MarketTab.defaultProps = {
  badge: null,
};

export default MarketTab;
