import PropTypes from 'prop-types';
import classnames from 'classnames';

import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { useToggle } from 'bv-hooks';

const Frame = ({ parentOutcomePrice, LTOPriceRange, children }) => {
  const [open, toggleOpen] = useToggle(
    parentOutcomePrice > 1.01 && parentOutcomePrice < LTOPriceRange,
  );

  return (
    <div>
      <div className="lengthen-the-odds-button-wrapper">
        <Button
          className={classnames('lengthen-the-odds-button', { open })}
          onClick={toggleOpen}
          inverse
          secondary
        >
          {t('javascript.lengthen_the_odds.title')}
        </Button>
      </div>
      <div className={classnames('lengthen-the-odds-wrapper', { open })}>
        {open && children}
      </div>
    </div>
  );
};

Frame.propTypes = {
  parentOutcomePrice: PropTypes.number.isRequired,
  LTOPriceRange: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default Frame;
