import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { buildScoreboard } from '../essential_scores/helpers';
import { Redux as EssentialScoresRedux, Api as EssentialScoresApi } from '../essential_scores';

const useEssentialScoreboards = (eventId) => {
  const getEventScoreboard = useMemo(EssentialScoresRedux.selectors.makeGetEventScoreboard, []);
  const scoreboard = useSelector((state) => getEventScoreboard(state, { eventId }), isEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    EssentialScoresApi
      .fetchEssentialScores({ eventIds: [eventId] })
      .then((response) => {
        dispatch(
          EssentialScoresRedux
            .actionCreators
            .upsertEssentialScores(response),
        );
      });

    return () => {
      dispatch(EssentialScoresRedux
        .actionCreators
        .removeEssentialScores([eventId]),
      );
    };
  }, [eventId]);

  return buildScoreboard(scoreboard);
};

export default useEssentialScoreboards;
