import { createSelector } from 'reselect';
import { isMarketClosed } from 'sportsbook-helpers';
import { getMarkets } from 'sportsbook-markets-selector';

export const getClosedMarketsIds = createSelector(
  getMarkets,
  (markets) => Object.values(markets || {})
    .filter(isMarketClosed)
    .map(({ id }) => id),
);
