// The idea of this function is to the data in an useful way for the components
// Hopefully the BE can be adapted to return the data in this way
// A market is always a list of periods, with length 1 when there is just 1
// Each period can have many markets data that has to be shown as a set (is_set)
const buildTitle = (market) => [
  market.description,
  market.period_description,
].filter(Boolean).join(' - ');

const getPeriods = (market) => (
  market.is_period_set ? market.periods : [market]
);

const applyPeriodMapping = (period) => ({
  marketGroups: period.is_set ? period.markets : [period],
  label: period.period_abbreviation || period.period,
  sportId: period.sport_id,
  ui: period.ui,
});

export default (market) => ({
  cashoutEligible: market.cashout_eligible,
  goesInPlay: market.goes_in_play,
  periods: getPeriods(market).map(applyPeriodMapping),
  title: buildTitle(market),
});
