import PropTypes from 'prop-types';
import { isGloballyEnabled } from 'bv-services/features';
import { connect } from 'react-redux';
import { makeGetRunner } from './selector';

const presentRunner = (runner) => {
  if (runner && isGloballyEnabled('timeform')) {
    return {
      age: runner.age,
      comment: runner.comment,
      eyeCatcher: runner.eye_catcher,
      gender: runner.gender,
      performances: runner.performances,
      starRating: runner.star_rating,
      trainerFullName: runner.trainer_full_name,
      attributes: runner.attributes,
      smartStat: runner.smartStat,
    };
  }

  return undefined;
};

export default (WrappedComponent) => {
  const makeMapStateToProps = () => {
    const getRunner = makeGetRunner();

    return (state, ownProps) => ({
      // TODO: ownProps.runner check is just needed for Greyhounds
      runner: ownProps.runner ? ownProps.runner : presentRunner(getRunner(state, ownProps)),
      // --------------------------------------------------------
    });
  };

  const WithRunner = (props) => <WrappedComponent {...props} />;

  WithRunner.propTypes = {
    id: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    runner: PropTypes.instanceOf(Object).isRequired,
  };

  WithRunner.displayName = 'withRunner';

  return connect(makeMapStateToProps)(WithRunner);
};
