import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetEvent } from '../selector';

export default (eventId) => {
  const getEvent = useMemo(
    makeGetEvent,
    [],
  );

  return useSelector((state) => getEvent(state, { eventId }));
};
