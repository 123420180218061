import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarketTab from './market_tab';

const MarketTabs = ({ markets, activeMarketId, setActiveMarketId }) => (
  markets.map((market) => (
    <MarketTab
      market={market}
      isActive={market.id === activeMarketId}
      setActiveMarketId={setActiveMarketId}
    />
  ))
);

MarketTabs.propTypes = {
  markets: PropTypes.instanceOf(Array).isRequired,
  activeMarketId: PropTypes.instanceOf(Object).isRequired,
  setActiveMarketId: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  markets: props.marketsIds.map((marketId) => state.markets[marketId]),
});

export default connect(mapStateToProps)(MarketTabs);
