// TODO: Too much logic for just an icon
// raceNumber check to be done in BE, raceNumber not used for any other thing
// Only one field with the icon URL, get sizes from sport id (css)
import PropTypes from 'prop-types';
import Silk from 'Sportsbook/components/silk';
import CssSilk from './css_silk';

const Icon = ({
  raceNumber, colour, trap, sportEventPathId,
}) => (
  <>
    <Silk silk={colour} />
    {(trap && raceNumber && (
      <img className="outcome-icon outcome-icon--trap" alt="" src={trap} />
    )) || ''}
    <CssSilk raceNumber={raceNumber} sportEventPathId={sportEventPathId} />
  </>
);

Icon.propTypes = {
  raceNumber: PropTypes.number.isRequired,
  colour: PropTypes.string.isRequired,
  trap: PropTypes.string.isRequired,
  sportEventPathId: PropTypes.number.isRequired,
};

export default Icon;
