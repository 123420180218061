import { compose, isEqual } from 'underscore';
import { locale } from 'bv-i18n';

import getSuspensionReasons from '../selectors/get_suspension_reasons';
import Api from '../../api';
import fetchSuspensionReasonsSuccess from './fetch_suspension_reasons_success';

export default () => (dispatch, getState) => {
  const suspensionReasons = getSuspensionReasons(getState());

  // We're gonna fetch the suspension reasons just once per app
  // loading, this will be reused on every component that needs them
  if (isEqual(suspensionReasons, {})) {
    Api.fetchSuspensionReasons(locale())
      .then(compose(dispatch, fetchSuspensionReasonsSuccess));
  }
};
