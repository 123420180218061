/* eslint no-param-reassign: off */
import { createSelector } from 'reselect';

import { getBetslipOutcomeIds } from 'Sportsbook/betslip/selector';
import { makeGetMarketOutcomes } from 'Sportsbook/markets/selector';

const getOutcomesIds = (state, props) => props.outcomesIds;

export const makeGetOutcomesIdsInBetslip = () => createSelector(
  [getBetslipOutcomeIds, getOutcomesIds],
  (singles, outcomesIds) => outcomesIds.filter((oid) => singles.includes(oid)),
);

const getSplitFn = (state, props) => props.splitFn;
const getNSelects = (state, props) => props.nSelects;

export const makeGetDataForSelects = () => {
  const originalGetMarketOutcomes = makeGetMarketOutcomes();

  const getMarketOutcomes = (state, props) => (
    originalGetMarketOutcomes(state, { id: props.marketId })
  );

  return createSelector(
    [getMarketOutcomes, getSplitFn, getNSelects],
    (outcomes, splitFn, nSelects) => (
      outcomes.reduce((accum, outcome) => {
        const outcomeValues = splitFn(outcome.desc || outcome.des);
        if (outcome.h || outcome.hidden) {
          return accum;
        }

        accum.outcomesValues[outcome.id] = outcomeValues;
        accum.selectsOptions = accum.selectsOptions.map((selectOptions, index) => (
          selectOptions.includes(outcomeValues[index])
            ? selectOptions
            : [...selectOptions, outcomeValues[index]]
        ));

        return accum;
      }, {
        outcomesValues: {},
        selectsOptions: Array(nSelects).fill([]),
      })
    ),
  );
};

export default {
  makeGetOutcomesIdsInBetslip,
  makeGetDataForSelects,
};
