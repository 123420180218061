import { getJSON } from 'bv-fetch';
import Services from '../services';

export default ({
  structure,
  filter,
  meetingId,
  includeEvents,
  segregateEventTypes,
  timeRange,
  eventStatus,
}) => getJSON('/api/sports_tree', {
  ...(meetingId ? { meeting_id: meetingId } : { filter: Services.parseFilter(filter) }),
  structure,
  include_events: includeEvents,
  segregate_event_types: segregateEventTypes,
  time_range: timeRange,
  event_status: eventStatus,
});
