import { betBuilderSupported } from 'sportsbook-helpers';
import { useFeature } from 'bv-hooks';

export default ({ marketTypeId }) => {
  const [betBuilderEnabled, featureResolved] = useFeature('bet-builder');

  const supported = featureResolved
    && betBuilderEnabled
    && betBuilderSupported(marketTypeId);

  return { supported };
};
