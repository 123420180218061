const parseSpread = (spread) => {
  const floatSpread = parseFloat(spread);

  return `${floatSpread > 0 ? '+' : ''}${floatSpread.toFixed(1)}`;
};

const buildSpread = (spread, spread2) => (
  `${parseSpread(spread)}${spread2 ? `, ${parseSpread(spread2)}` : ''}`
);

export default (description) => {
  const match = description.match(/.*\(([^,]*)(,(.*))?\)/);

  return match ? buildSpread(match[1], match[3]) : description;
};
