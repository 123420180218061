import PropTypes from 'prop-types';

const TitleValue = ({ title, value }) => (
  <div className="racecard-modal__runner-details--title">
    <strong>{title}</strong>
    {' '}
    <span>{value}</span>
  </div>
);

TitleValue.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TitleValue;
