import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const ShowFullList = ({ displayedOutcomesCount, currentBandOutcomesCount, showAllOutcomes }) => (
  displayedOutcomesCount < currentBandOutcomesCount ? (
    <div className="priceitup-show-more-wrapper">
      <Button
        className="priceitup-show-more"
        onClick={showAllOutcomes}
        primary
      >
        { t('show_full_list') }
      </Button>
    </div>
  ) : null
);

ShowFullList.propTypes = {
  displayedOutcomesCount: PropTypes.number.isRequired,
  currentBandOutcomesCount: PropTypes.number.isRequired,
  showAllOutcomes: PropTypes.func.isRequired,
};

export default ShowFullList;
