import { useContext } from 'react';
import PropTypes from 'prop-types';

import Context from 'Sportsbook/context';
import { useEvent } from 'Sportsbook/events/hooks';
import SportsbookMarketHeader from 'Sportsbook/markets/components/header';
import getMarketTitle from 'MarketsV2/helpers/get_market_title';
import HeaderSubtitle from './header_subtitle';

const Header = ({ collapsed, market, onClick }) => {
  const { sportEventPathId } = useContext(Context);
  const event = useEvent(market.eId);

  return (
    <>
      <SportsbookMarketHeader
        folded={collapsed}
        toggleFolded={onClick}
        market={{
          ...market,
          title: getMarketTitle(sportEventPathId, event, market),
        }}
      />
      <HeaderSubtitle
        eventId={market.eId}
        sportEventPathId={sportEventPathId}
      />
    </>
  );
};

Header.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Header;
