import PropTypes from 'prop-types';
import { range } from 'underscore';
import { t } from 'bv-i18n';

const Timeform = ({ starRating, comment }) => (
  <div className="extended-data">
    <ul className="rating">
      <span>{t('time_form.star_rating')}</span>
      {range(5).map((number) => (
        <li className={(starRating > number) ? 'checked' : ''} />
      ))}
    </ul>
    <p>
      <span>{t('time_form.timeform')}</span>
      {comment}
    </p>
  </div>
);

Timeform.propTypes = {
  starRating: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
};

export default Timeform;
