export default (backboneMarket) => {
  const piuAttributes = backboneMarket.price_it_up;

  // Precondition: If piuAttributes are not present return empty object
  if (!piuAttributes) return {};

  return {
    betBuilderButtonPos: piuAttributes.bet_builder_button_pos,
    defaultTab: piuAttributes.default_tab,
    outcomesToShow: piuAttributes.outcomes_to_show,
    priceBands: piuAttributes.price_bands,
  };
};
