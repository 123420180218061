import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

import present from 'MarketsV2/presenters/markets';
import normalize from 'Sportsbook/normalizers/markets';

export default ({ sportId, eventId, periodKey }) => (
  getJSON(`/api/sportsbook/sports/${sportId}/events/${eventId}/markets`, { c: locale(), period_key: periodKey })
    .catch(() => [])
    // Parse to names expected by redux
    .then(present)
    .then(normalize)
);
