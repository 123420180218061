import _, {
  property, compose, partial, pluck,
} from 'underscore';

import optionsApplier from './options/applier';

const getId = property('id');

// TODO: To receive only ids from backend?
const getLtoOutcomes = compose(partial(pluck, _, 'id'), property('outright_lto_outcomes'));

const transform = (mappedElement, originalObject) => {
  if (typeof mappedElement === 'function') {
    return mappedElement(originalObject);
  }

  return originalObject[mappedElement];
};

const applyMapping = (mapping) => (originalObject) => Object
  .keys(mapping)
  .reduce((acc, key) => ({
    ...acc,
    [key]: transform(mapping[key], originalObject),
  }), {});

// ---------- Build Market Mapping Applier

const marketMapping = {
  id: 'id',
  des: 'description',
  st: 'status_id',
  eId: 'event_id',
  pltD: 'place_terms_deduction',
  pltDes: 'place_terms_description',
  mtId: 'market_type_id',
  p: 'period_description',
  ew: 'ew',
  o: (market) => market.outcomes.map(getId),
  // pltNp -> missing
  // pId -> missing
  // mbl -> missing
  // ca -> missing
  // goesInPlay -> ???
  // sportId -> ???
};

const applyMarketMapping = applyMapping(marketMapping);

// ---------- Build Outcome Mapping Applier

const outcomeMapping = {
  id: 'id',
  mId: 'mId',
  desc: 'description',
  prid: 'price_id',
  prd: 'decimal_price',
  pr: 'formatted_price',
  sp: 'sp',
  numBets: 'num_bets',
  pp: 'pen_price_text',
  app: 'anti_pen_price_text',
  enabled: 'enabled',
  // Race outcomes data, probably not used anymore in this backbone adapter
  form: 'form',
  weight: 'runner_weight',
  jockey: 'jockey',
  raceNumber: 'race_number',
  silk: 'silk',
  trainer: 'trainer',
  horseNumber: 'horse_number',
  // TODO FOR SIMPLER API:
  // Icons are fixed based in number, do them as virtuals in CSS
  // Add icon if mapping exists, we would get rid of extra info in API
  // And API would just only send info obtained from OMDS
  trap: 'trap',
  // TODO: The outcomes should be normalised to be added to the state
  // Not done yet (did it and reverted it) because the outcomes has different format
  // Unify APIs and improve this
  ltoOutcomes: getLtoOutcomes,
  spread: 'spread',
};

const applyOutcomeMapping = applyMapping(outcomeMapping);

// --------------------------------------------

export default (backboneMarket) => {
  // Obtain plain objects from backbone models ------------
  const backboneMarketObject = backboneMarket;
  const backboneOutcomeObjects = backboneMarketObject
    .outcomes
    .map((o) => ({
      ...o,
      // Here we can decorate the outcome if needed with some info
      // from its market, in this case we're adding the market Id to be
      // able to reference directly a market given one of its outcomes
      mId: backboneMarketObject.id,
      // ------------------------------------------------
    }));

  // Perform --------------------------------------------
  return {
    market: applyMarketMapping(backboneMarketObject),
    outcomes: backboneOutcomeObjects.map(applyOutcomeMapping),
    options: optionsApplier(backboneMarket),
  };
};
