import { isGloballyEnabled } from 'bv-services/features';

// TODO: Just needed for Greyhounds that are still fetching timeForm as part of the market

export default (backboneMarket) => {
  const eventTimeForm = backboneMarket.event_time_form;

  // TODO: Feature flipper check should be done in BE
  // Asian brands do not have timeform and data is included in the responses
  if (!(eventTimeForm && isGloballyEnabled('timeform'))) {
    return { runnersByOutcomeId: {} };
  }

  return {
    runnersByOutcomeId: (eventTimeForm.runners || []).reduce((accum, runner) => ({
      ...accum,
      [runner.outcome_id]: {
        age: runner.age,
        comment: runner.comment,
        eyeCatcher: runner.eye_catcher,
        gender: runner.gender,
        performances: runner.performances,
        starRating: runner.star_rating,
        trainerFullName: runner.trainer_full_name,
      },
    }), {}),
  };
};
