import { useContext } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'bv-components';
import { useToggle } from 'bv-hooks';
import Context from 'Sportsbook/context';
import withMarket from 'Sportsbook/markets/with_market';

import getMarketComponent from 'MarketsV2/helpers/get_market_component';
import EventStatus from './event_status/event_status';
import Header from './header';

const MarketCard = ({ eventId, market, open }) => {
  const { sportEventPathId } = useContext(Context);
  const [collapsed, toggleCollapsed] = useToggle(!open);

  const Market = getMarketComponent(market.ui);

  return (
    <div className="market-card">
      <Header market={market} onClick={toggleCollapsed} collapsed={collapsed} />
      <EventStatus eventId={eventId} sportEventPathId={sportEventPathId} />
      {collapsed || (
        <Card className="market-card__content">
          <div className="market-card__market-view">
            <Market market={market} />
          </div>
        </Card>
      )}
    </div>
  );
};

MarketCard.propTypes = {
  eventId: PropTypes.number.isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
};

export default withMarket(MarketCard);
