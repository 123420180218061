import { omit, pick, difference } from 'underscore';
import { addMarkets, upsertMarkets, removeMarkets } from 'Sportsbook/markets/duck';
import { addOutcomes, upsertOutcomes, removeOutcomes } from 'Sportsbook/outcomes/duck';

export default (
  markets,
  outcomes,
  prevMarketsIds,
  prevOutcomesIds,
  dispatch,
) => {
  const dispatchIfPresent = (actionCreator, values) => {
    if (values.length) dispatch(actionCreator(values));
  };

  // New markets/outcomes should be added
  dispatchIfPresent(addMarkets, Object.values(omit(markets, prevMarketsIds)));
  dispatchIfPresent(addOutcomes, Object.values(omit(outcomes, prevOutcomesIds)));

  // Old markets/outcomes should be upserted
  dispatchIfPresent(upsertMarkets, Object.values(pick(markets, prevMarketsIds)));
  dispatchIfPresent(upsertOutcomes, Object.values(pick(outcomes, prevOutcomesIds)));

  // What was there before and it's not anymore should be removed
  dispatchIfPresent(removeMarkets, difference(prevMarketsIds, Object.keys(markets)).map(Number));
  dispatchIfPresent(removeOutcomes, difference(prevOutcomesIds, Object.keys(outcomes)).map(Number));
};
