import { get, set } from 'bv-local-storage';

const SPORTSBOOK_OPTIONS_TOGGLE = 'SPORTSBOOK_OPTIONS.TOGGLE';

const knowOptions = ['timeform'];

export const toggle = (option, checked) => (dispatch) => {
  if (!knowOptions.includes(option)) return;

  set(`${option}_visible`, checked);

  dispatch({
    type: SPORTSBOOK_OPTIONS_TOGGLE,
    option,
    checked,
  });
};

const initialState = knowOptions.reduce((accum, option) => ({
  ...accum,
  [`${option}Checked`]: get(`${option}_visible`),
}), {});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SPORTSBOOK_OPTIONS_TOGGLE:
      return {
        ...state,
        [`${action.option}Checked`]: action.checked,
      };
    default:
      return state;
  }
};
