import { t } from 'bv-i18n';

import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';

import WithSelectors from '../with_selectors/entry';

const splitFn = (desc) => [desc];

const OneSelect = (props) => (
  <WithSelectors
    {...props}
    splitFn={splitFn}
    nSelects={1}
    titles={[t('select_outcome')]}
  />
);

export default withBackboneMarket(withMarket(OneSelect));
