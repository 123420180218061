import { createSelector } from 'reselect';
import getSuspensionReasons from './get_suspension_reasons';

const getOwnSportId = (state, props) => props.sportId;

export default () => createSelector(
  getSuspensionReasons,
  getOwnSportId,
  (suspensionReasons, sportId) => suspensionReasons[sportId],
);
