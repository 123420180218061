import PropTypes from 'prop-types';
import withOutcome from 'Sportsbook/outcomes/with_outcome';

export const Outcome = ({
  disabled, selected, toggleBetslip, overridenDesc, outcome,
}) => (
  <div
    className={`bvs-button-sport market-outcome ${disabled ? 'disabled' : ''} ${selected ? ' active' : ''}`}
    onClick={toggleBetslip}
  >
    <span className="market-outcome__label">{overridenDesc || outcome.desc}</span>
    <span>{outcome.formattedPrice}</span>
  </div>
);

Outcome.propTypes = {
  outcome: PropTypes.shape({
    desc: PropTypes.string.isRequired,
    formattedPrice: PropTypes.string.isRequired,
  }).isRequired,
  overridenDesc: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Outcome.defaultProps = {
  overridenDesc: undefined,
  disabled: false,
};

export default withOutcome(Outcome);
