import Frame from './frame';
import MarketSlider from './market_slider';

const LengthenTheOdds = (props) => (
  <Frame {...props}>
    <MarketSlider {...props} />
  </Frame>
);

export default LengthenTheOdds;
