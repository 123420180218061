import { useState } from 'react';
import PropTypes from 'prop-types';

import Selections from './selections';
import BetButton from './bet_button';

const MarketSlider = ({ outcomes }) => {
  const [selectedId, setSelectedId] = useState(outcomes[0]);

  return (
    <div className="lengthen-the-odds">
      <Selections
        outcomes={outcomes}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
      <BetButton id={selectedId} />
    </div>
  );
};

MarketSlider.propTypes = {
  outcomes: PropTypes.instanceOf(Array).isRequired,
};

export default MarketSlider;
