import { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon as BVIcon } from 'bv-components';
import { t } from 'bv-i18n';

import withOutcome from 'Sportsbook/outcomes/with_outcome';
import withRunner from 'Sportsbook/outcomes/with_runner';
import PreviousPrice from 'Sportsbook/components/previous_price';
import withOptions from 'Sportsbook/options/with_options';
import Context from 'Sportsbook/context';

import getInfoLines from './helpers/get_info_lines';
import getOutcomeDescription from './helpers/get_outcome_description';
import Icon from './components/icon';
import Timeform from './components/timeform';

export const Outcome = ({
  outcome, marketDisabled, selected, runner, timeformChecked, toggleBetslip,
}) => {
  const className = classnames('racing-default-outcome', {
    disabled: !outcome.enabled || marketDisabled,
  });

  const priceClassName = classnames('bvs-button-sport', {
    active: selected,
  });

  const { sportEventPathId } = useContext(Context);

  return (
    <div className={className}>
      <div className="flex flex--centered">
        <Icon {...outcome} sportEventPathId={sportEventPathId} />
        <div className="flex--grow">
          <strong>
            {getOutcomeDescription(sportEventPathId, outcome)}
          </strong>
          {runner && runner.eyeCatcher && <BVIcon id="eye-catcher-active" className="eye-catcher" />}
          {getInfoLines(sportEventPathId, outcome, runner).map((line) => (
            <div><small>{line}</small></div>
          ))}
        </div>
        <div className="racing-default-outcome__price-button">
          <span className={priceClassName} onClick={toggleBetslip}>
            {outcome.enabled ? outcome.formattedPrice : t('nr')}
          </span>
          {outcome.enabled && (
            <PreviousPrice
              previousPrice={outcome.pp}
              antePreviousPrice={outcome.app}
            />
          )}
        </div>
      </div>
      {runner && timeformChecked && <Timeform {...runner} />}
      <hr />
    </div>
  );
};

Outcome.propTypes = {
  folded: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  runner: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  timeformChecked: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  marketDisabled: PropTypes.bool.isRequired,
};

export default withOutcome(withOptions(withRunner(Outcome)));
