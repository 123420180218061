import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import withOutcome from 'Sportsbook/outcomes/with_outcome';

const labelText = ({ spread }) => (
  parseFloat(spread) === 1
    ? t('javascript.lengthen_the_odds.button.single')
    : t('javascript.lengthen_the_odds.button.plural', { length: spread.toString() })
);

const BetButton = ({ selected, toggleBetslip, outcome }) => (
  <div className={`bvs-button-sport bet-button ${selected ? 'active' : ''}`} onClick={toggleBetslip}>
    {labelText(outcome)}
  </div>
);

BetButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
};

export default withOutcome(BetButton);
