import PropTypes from 'prop-types';

import { ContentIcon } from 'bv-components';
import withOutcome from 'Sportsbook/outcomes/with_outcome';

export const SelectedOutcome = ({ outcome, addToBetslip }) => (
  <div className="flex flex--centered">
    <span>{outcome.formattedPrice}</span>
    <ContentIcon big add onClick={addToBetslip} />
  </div>
);

SelectedOutcome.propTypes = {
  addToBetslip: PropTypes.func.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
};

export default withOutcome(SelectedOutcome);
