import PropTypes from 'prop-types';
import Outcome from 'Sportsbook/outcomes/views/bet_boost';
import { t } from 'bv-i18n';

const BetBoost = ({ market }) => (
  <div className="market-view-body-wrapper bet-boost-market">
    <div className="outcomes-column-title-wrapper">
      <div className="outcomes-column-title">{t('javascript.was')}</div>
      <div className="outcomes-column-title boost">{t('javascript.boost')}</div>
    </div>
    <div className="market-view-body">
      {
        market.o.map((outcomeId) => (
          <Outcome id={outcomeId} suspended={market.st === 2} />
        ))
      }
    </div>
  </div>
);

BetBoost.propTypes = {
  market: PropTypes.shape({
    st: PropTypes.number.isRequired,
    o: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};

export default BetBoost;
