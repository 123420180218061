import { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'underscore';

import { SliderSelector } from 'bv-components';
import { makeGetOutcomesByIds } from 'Sportsbook/outcomes/selector';
import formatPrice from 'Sportsbook/helpers/format_price';

const Selections = ({ setSelectedId, outcomes, selectedId }) => {
  const onChange = useCallback((event) => {
    setSelectedId(parseInt(event.target.value, 10));
  }, [setSelectedId]);

  const selections = useMemo(() => (
    outcomes.map((outcome) => ({
      id: outcome.id,
      title: formatPrice(outcome.pr),
    }))
  ), [outcomes]);

  return (
    <SliderSelector
      selections={selections}
      selectedId={selectedId}
      onChange={onChange}
      big
    />
  );
};

Selections.propTypes = {
  setSelectedId: PropTypes.func.isRequired,
  outcomes: PropTypes.instanceOf(Array).isRequired,
  selectedId: PropTypes.number.isRequired,
};

const makeMapStateToProps = () => {
  const getOutcomesById = makeGetOutcomesByIds();

  return (state, props) => ({
    outcomes: getOutcomesById(state, props),
  });
};

export default connect(makeMapStateToProps, null, null, {
  areStatePropsEqual: isEqual,
})(Selections);
