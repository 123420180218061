import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetMarket } from 'Sportsbook/markets/selector';
import MarketSliderView from './market_slider_view';

const MarketSliderRedux = (props) => {
  const getMarket = useMemo(makeGetMarket, []);
  const market = useSelector((state) => getMarket(state, props));

  return <MarketSliderView {...props} outcomes={market.o} />;
};

export default MarketSliderRedux;
