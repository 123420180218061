import PropTypes from 'prop-types';
import classnames from 'classnames';

import CashoutIcons from 'Sportsbook/components/cashout_icons';
import { ToggleIcon } from 'bv-components';

const Header = ({ folded, market, toggleFolded }) => (
  <div
    className={classnames('bvs-card-header bvs-link', { folded })}
    onClick={toggleFolded}
  >
    <h3 className="bvs-header market-header flex--grow">{market.title}</h3>
    <div className="flex--no-shrink">
      <CashoutIcons market={market} />
      <ToggleIcon folded={folded} light solid />
    </div>
  </div>
);

Header.propTypes = {
  folded: PropTypes.bool.isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  toggleFolded: PropTypes.func.isRequired,
};

export default Header;
