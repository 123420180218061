import PropTypes from 'prop-types';

const EnhancedPrice = ({ enhancedPrice, oldPrice }) => (
  <div className="enhanced-odds__sportsbook">
    <span className="enhanced-odds__odds">
      <span className="bvs-event-icon is-bet-boost" />
      {enhancedPrice}
    </span>
    <span className="enhanced-odds__previous-odds">{oldPrice}</span>
  </div>
);

EnhancedPrice.propTypes = {
  enhancedPrice: PropTypes.string.isRequired,
  oldPrice: PropTypes.string.isRequired,
};

export default EnhancedPrice;
