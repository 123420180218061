import PropTypes from 'prop-types';
import SmartStatRibbon from './smart_stat_ribbon';

const SmartStat = ({
  status, ribbonTitle, title, subtitle, description,
}) => (
  <div className={`racecard-smart-stat racecard-smart-stat__${status}`}>
    <SmartStatRibbon status={status} ribbonTitle={ribbonTitle} />

    <div className="racecard-smart-stat__content">
      <div className="racecard-smart-stat__title">{title}</div>
      <div className="racecard-smart-stat__subtitle">{subtitle}</div>
      <div className="racecard-smart-stat__text">{description}</div>
    </div>
  </div>
);

SmartStat.propTypes = {
  ribbonTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default SmartStat;
