import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import classnames from 'classnames';
import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';
import { makeGetMarketVisibleOutcomes } from 'Sportsbook/markets/selector';
import { connect } from 'react-redux';
import Outcome from 'Sportsbook/outcomes/views/price_it_up/entry';
import PriceBands from './components/price_bands';
import OutcomesDisclaimer from './components/outcomes_disclaimer';
import ShowFullList from './components/show_full_list';
import PIUButton from './components/piu_button';
import bandOutcomes, { getPriceBandsWithOutcomes } from './helpers/band_outcomes';

export const PriceItUp = ({
  market: {
    eId,
    mtId,
    disabled,
  },
  outcomes,
  options: {
    priceBands,
    defaultTab,
    outcomesToShow,
    eventStage,
    betBuilder2,
  },
}) => {
  const liveEvent = eventStage === 'live';

  // Set the number of outcomes to display by default and the callback to display them all
  const [outcomesToDisplay, setOutcomesToDisplay] = useState(
    outcomesToShow || 5,
  );

  const showAllOutcomes = useCallback(() => setOutcomesToDisplay(100000), []);

  // Get the price bands with outcomes and the current active one
  const availablePriceBands = getPriceBandsWithOutcomes(outcomes, priceBands);

  const [activeTab, setActiveTab] = useState(
    availablePriceBands[defaultTab] ? defaultTab : 0,
  );

  const activePriceBand = availablePriceBands[activeTab] || availablePriceBands[0];

  // Outcomes belonging to the current price band
  const currentBandOutcomes = bandOutcomes(outcomes, activePriceBand);

  // Outcomes that are visible from the current price band
  const displayedOutcomes = currentBandOutcomes
    .slice(0, outcomesToDisplay);

  return (
    <>
      <div className={classnames('priceitup-market', {
        betBuilder2,
        'with-see-more': displayedOutcomes.length < currentBandOutcomes.length,
      })}
      >
        <PIUButton
          eventId={eId}
          marketTypeId={mtId}
          liveEvent={liveEvent}
          betBuilder2={betBuilder2}
        />
        <div className="priceitup-outcomes">
          <PriceBands
            priceBands={availablePriceBands}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <OutcomesDisclaimer
            currentBandOutcomesCount={currentBandOutcomes.length}
            outcomesCount={outcomes.length}
          />

          <div className="priceitup-events">
            {
              displayedOutcomes.map((outcome) => (
                <Outcome
                  id={outcome.id}
                  eventId={eId}
                  marketTypeId={mtId}
                  clickSource="bb-automated"
                  disabled={disabled || false}
                  liveEvent={liveEvent}
                  betBuilder2={betBuilder2}
                />
              ))
            }
          </div>
        </div>
      </div>
      <ShowFullList
        displayedOutcomesCount={displayedOutcomes.length}
        currentBandOutcomesCount={currentBandOutcomes.length}
        showAllOutcomes={showAllOutcomes}
      />
    </>
  );
};

PriceItUp.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  outcomes: PropTypes.instanceOf(Array).isRequired,
  market: PropTypes.shape({
    eId: PropTypes.number.isRequired,
    mtId: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
};

const makeMapStateToProps = () => {
  const getMarketOutcomes = makeGetMarketVisibleOutcomes();

  return (state, ownProps) => ({
    outcomes: getMarketOutcomes(state, ownProps),
  });
};

export default withBackboneMarket(withMarket(connect(makeMapStateToProps, null, null, {
  areStatePropsEqual: isEqual,
})(PriceItUp)));
