import { connect } from 'react-redux';
import { compose } from 'underscore';

import { toggle } from './duck';

const mapStateToProps = (state) => ({
  ...state.sportsbookOptions,
});

const mapDispatchToProps = (dispatch) => ({
  toggle: compose(dispatch, toggle),
});

export default (WrappedComponent) => (
  connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
);
