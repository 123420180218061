import PropTypes from 'prop-types';

const SmartStatRibbon = ({ status, ribbonTitle }) => (
  <div className={`bvs-ribbon bvs-ribbon__left racecard-ribbon-stat__${status}`}>
    <div><p>{ ribbonTitle }</p></div>
  </div>
);

SmartStatRibbon.propTypes = {
  ribbonTitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default SmartStatRibbon;
