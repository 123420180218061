import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { StarRating } from 'bv-components';

const Rating = ({ starRating }) => (
  <p>
    <span>{t('javascript.race_overview.rating')}</span>
    <StarRating rating={starRating} />
  </p>
);

Rating.propTypes = {
  starRating: PropTypes.number.isRequired,
};

export default Rating;
