import PropTypes from 'prop-types';
import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';
import RacingDefaultOutcome from 'Sportsbook/outcomes/views/racing_default/entry';

export const RacingDefault = ({
  outcomeComponent, market, options, outcomeOptions,
}) => {
  const RacingOutcome = outcomeComponent;

  return market.o.map((outcomeId) => (
    <RacingOutcome
      id={outcomeId}
      // TODO: Just needed for Greyhounds that are still fetching timeForm as part of the market
      runner={options?.runnersByOutcomeId[outcomeId]}
      // ----------------------------------------------------------------------------------------
      eventId={market.eId}
      outcomeOptions={outcomeOptions}
      marketDisabled={market.st !== 1}
    />
  ));
};

RacingDefault.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.number.isRequired,
    o: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  outcomeComponent: PropTypes.instanceOf(Object),
};

RacingDefault.defaultProps = {
  outcomeComponent: RacingDefaultOutcome,
};

export default withBackboneMarket(withMarket(RacingDefault));
