import PropTypes from 'prop-types';
import EventLink from './event_link';

const EventsList = ({
  events, activeEventId, activeRef, onEventClick, isTab,
}) => (
  <ul className="btns__list">
    {events.map((event) => (
      <li key={event.id}>
        <EventLink
          {...event}
          active={activeEventId === event.id}
          activeRef={activeRef}
          onEventClick={onEventClick}
          isTab={isTab}
        />
      </li>
    ))}
  </ul>
);

EventsList.propTypes = {
  activeRef: PropTypes.func.isRequired,
  onEventClick: PropTypes.func.isRequired,
  activeEventId: PropTypes.number.isRequired,
  events: PropTypes.instanceOf(Array).isRequired,
  isTab: PropTypes.bool,
};

EventsList.defaultProps = {
  isTab: false,
};

export default EventsList;
