import PropTypes from 'prop-types';
import { chunk } from 'underscore';

import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';
import DefaultOutcome from 'Sportsbook/outcomes/views/default/entry';

export const TwoColumns = (props) => {
  const Outcome = props.outcomeComponent;

  return chunk(props.market.o, 2).map((outcomeIds) => (
    <div className="outcomes-row">
      {outcomeIds.map((outcomeId) => <Outcome id={outcomeId} />)}
    </div>
  ));
};

// TODO: Define market shape in another module and reuse it
TwoColumns.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.number.isRequired,
    o: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
  outcomeComponent: PropTypes.instanceOf(Object),
};

TwoColumns.defaultProps = {
  outcomeComponent: DefaultOutcome,
};

export default withBackboneMarket(withMarket(TwoColumns));
