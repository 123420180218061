import PropTypes from 'prop-types';

import { usePriceFormatId } from 'bv-hooks';
import formatPrice from 'Sportsbook/helpers/format_price';

const PreviousPrice = ({ previousPrice, antePreviousPrice }) => {
  const priceFormatId = usePriceFormatId();

  if (!previousPrice) return null;

  return (
    <div className="upcoming-races__outcome-subprice">
      { antePreviousPrice && [
        formatPrice(antePreviousPrice, priceFormatId),
        <span className="upcoming-races__outcome-spacer" />,
      ]}
      {formatPrice(previousPrice, priceFormatId)}
    </div>
  );
};

PreviousPrice.propTypes = {
  previousPrice: PropTypes.string.isRequired,
  antePreviousPrice: PropTypes.string.isRequired,
};

export default PreviousPrice;
