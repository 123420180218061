/* eslint no-unused-vars: 'off', arrow-parens: 'off', no-param-reassign: 'off' */
// The above eslint rules are disabled because we wanted to follow The
// usual notation that can be found on the docs when writing middlewares
import { isDesktop } from 'bv';
import { publish } from 'bv-services/internal-event-bus';
import { setBetslipBetsNumber } from 'floating-navigation-helpers';
import { showBetslip } from 'right-sidebar';
import { ADD_OUTCOMES, UPSERT_OUTCOMES, UPDATE_OUTCOME } from '../outcomes/duck';
import {
  showMiniBetslip,
  hideMiniBetslip,
  ADD_TO_BETSLIP_INIT,
  ADD_TO_BETSLIP_SUCCESS,
  BETSLIP_SUBMIT_SUCCESS,
  RESET_BETSLIP_SUCCESS,
  REMOVE_FROM_BETSLIP_INIT,
  REMOVE_FROM_BETSLIP_SUCCESS,
  EMPTY_BETSLIP,
} from './duck';
import { updatePrices, updateSelectedBetslipTab } from './thunks';
import { showMultiplesTooltip } from './helpers';

const handleOutcomeUpdate = ({
  oldState, newState, action, dispatch,
}) => {
  const outcomeId = parseInt(action.outcomeId, 10);
  if ((oldState.betslip.outcomeIds || []).includes(outcomeId)) {
    const newOutcome = newState.outcomes[outcomeId];
    const oldOutcome = oldState.outcomes[outcomeId];
    const hasPriceChanged = oldOutcome && oldOutcome.prd && newOutcome.prd !== oldOutcome.prd;

    // If there's a change we need to care about, tell the betslip
    if (hasPriceChanged) {
      dispatch(updatePrices(newOutcome));
    }
  }
};

const betslipMiddleware = store => next => action => {
  const state = store.getState();
  const outcomeIds = (state.betslip.outcomeIds || []);
  const { currentTabSelectedManually } = state.betslip;

  switch (action.type) {
    case RESET_BETSLIP_SUCCESS:
      setBetslipBetsNumber(action.outcomeIds.length);
      store.dispatch(updateSelectedBetslipTab(currentTabSelectedManually, action.showResponse));
      break;

    case ADD_TO_BETSLIP_INIT:
      publish('Betslip::Add', action.outcomeId, {
        clickSource: action.opts.clickSource,
        enhancedOutcome: action.opts.enhancedOutcome,
      });

      // when we are going from nothing to the frist outcome added to the betslip
      // we set this flag to true for the UI to render
      // (if the feature is enabled and some other non-data related conditions)

      if (outcomeIds.length === 0) {
        store.dispatch(showMiniBetslip());
      } else {
        store.dispatch(hideMiniBetslip());
      }

      if (isDesktop()) showBetslip();

      break;

    // When there's as second add_to_betslip_init starts while the previous one
    // is still running, we need to make sure we don't end up with more than one
    // outcome on the betslip and the mini-betsip open
    case ADD_TO_BETSLIP_SUCCESS:
      if (action.outcomeIds.length > 1) {
        store.dispatch(hideMiniBetslip());
      }
      showMultiplesTooltip(action.showResponse.multiples);
      store.dispatch(updateSelectedBetslipTab(currentTabSelectedManually, action.showResponse));
      setBetslipBetsNumber(action.outcomeIds.length);
      break;

    case REMOVE_FROM_BETSLIP_INIT:
      // if this was the last + first outcome we are removing, hide the
      // mini-betslip panel before we load the contents
      if (outcomeIds.length === 1 && outcomeIds.includes(action.outcomeId)) {
        store.dispatch(hideMiniBetslip());
        window.RightSidebar.hide();
      }

      publish('Betslip::Remove', action.outcomeId);
      break;

    case REMOVE_FROM_BETSLIP_SUCCESS:
      if (action.showResponse.singles) {
        setBetslipBetsNumber(action.showResponse.singles.length);
      }
      showMultiplesTooltip(action.showResponse.multiples);
      store.dispatch(updateSelectedBetslipTab(currentTabSelectedManually, action.showResponse));
      break;

    case EMPTY_BETSLIP:
      setBetslipBetsNumber(0);
      window.RightSidebar.hide();
      store.dispatch(updateSelectedBetslipTab(currentTabSelectedManually, null));
      break;

    case BETSLIP_SUBMIT_SUCCESS:
      if (action.submitResponse && action.submitResponse.success) {
        publish('Betslip::SubmitSuccess');
      }
      break;

    default:
      break;
  }

  next(action);

  switch (action.type) {
    case UPDATE_OUTCOME:
      handleOutcomeUpdate({
        action,
        oldState: state,
        newState: store.getState(),
        dispatch: store.dispatch,
      });
      break;

    case ADD_OUTCOMES:
    case UPSERT_OUTCOMES:
      // some updates come as add/upsert, we still have to
      // copy the price / status from them to singles in betslip
      action.outcomes.forEach((outcome) => {
        handleOutcomeUpdate({
          action: { type: action.type, outcomeId: outcome.id },
          oldState: state,
          newState: store.getState(),
          dispatch: store.dispatch,
        });
      });
      break;

    default:
      break;
  }
};

export default betslipMiddleware;
