import EventIcon from './event_icons/event_icon';

const knownBages = [
  'BET_BOOST',
  'PIU',
  'STREAMING',
  'CORNERS',
  'LUCKY_DIP',
];

const EventIcons = ({ badges }) => (
  knownBages
    .filter((badge) => badges.includes(badge))
    .map((badge) => <EventIcon badge={badge} />)
);

export default EventIcons;
