import { useEffect } from 'react';
import { difference } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'bv-components';
import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';

import { handleMarketsAndOutcomes, withCancellation } from 'Sportsbook/services';

import fetchMarkets from './api/fetch_markets';
import MarketsView from './markets_view';
import { getClosedMarketsIds } from './selectors';

const MarketsContainer = ({
  sportId,
  eventId,
  periodKey,
  ...rest
}) => {
  const closedMarketsIds = useSelector(getClosedMarketsIds);
  const dispatch = useDispatch();

  const cancelableFetchMarkets = withCancellation((canceled = { current: false }) => (
    fetchMarkets({
      sportId,
      eventId,
      periodKey,
    })
      .then(({ entities: { markets = {}, outcomes = {} }, result }) => {
        if (canceled.current) return undefined;

        handleMarketsAndOutcomes(markets, outcomes, [], [], dispatch);

        return {
          marketsIds: result,
          outcomesIds: Object.keys(outcomes),
        };
      })
  ));

  const [{ marketsIds = [], outcomesIds = [] } = {}, loading] = useFetch(
    cancelableFetchMarkets.execute,
    [eventId],
  );

  // When eventId changes we cancel the fetch in case still in progress
  useEffect(() => cancelableFetchMarkets.cancel, [eventId]);

  // When marketsIds/outcomesIds changes we want to remove them from the state, they change together
  useEffect(() => () => {
    handleMarketsAndOutcomes({}, {}, marketsIds, outcomesIds, dispatch);
  }, [marketsIds, outcomesIds]);

  if (loading) return <Spinner />;

  return (
    <MarketsView
      sportEventPathId={sportId}
      marketsIds={difference(marketsIds, closedMarketsIds)}
      eventId={eventId}
      {...rest}
    />
  );
};

MarketsContainer.propTypes = {
  sportId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  periodKey: PropTypes.number,
};

MarketsContainer.defaultProps = {
  periodKey: null,
};

export default MarketsContainer;
