import { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Carousel } from 'bv-components';

const SliderNav = ({ elements }) => {
  const ref = useRef(null);

  return (
    <Carousel className="market-filter-wrapper" scrollStep={110} embedded activeRef={ref}>
      {elements.map((element) => (
        <Button
          label={element.label}
          active={element.active}
          onClick={element.onClick}
          ref={element.active ? ref : null}
          noClass
          className={classnames(
            'bvs-button-chip',
            { [`${element.icon.toLowerCase()}`]: element.icon },
          )}
          badge={element.badge}
        />
      ))}
    </Carousel>
  );
};

SliderNav.propTypes = {
  elements: PropTypes.instanceOf(Array).isRequired,
};

export default SliderNav;
