import PropTypes from 'prop-types';
import { format } from 'bv-helpers/price';
import { usePriceFormatId } from 'bv-hooks';
import classnames from 'classnames';

const bandLabel = (priceBand, priceFormatId) => {
  if (priceBand[0] === '<' || priceBand[0] === '>' || priceBand[1] === '>') {
    return [
      /\d/.test(priceBand[0]) ? priceBand[1] : priceBand[0],
      format(priceBand[/\d/.test(priceBand[0]) ? 0 : 1]),
    ].join(' ');
  }

  return [
    format(priceBand[0], priceFormatId),
    format(priceBand[1], priceFormatId),
  ].join(' - ');
};

const PriceBands = ({ priceBands, activeTab, setActiveTab }) => {
  const priceFormatId = usePriceFormatId();

  return (
    priceBands.length ? (
      <div className="priceitup-bands-wrapper">
        <div className="priceitup-bands">
          { priceBands.map((priceBand, index) => (
            <div className="priceitup-bands__item bvs-link">
              <button
                type="button"
                className={classnames('bvs-button-chip is-small', {
                  active: index === activeTab,
                })}
                onClick={() => setActiveTab(index)}
              >
                {bandLabel(priceBand, priceFormatId)}
              </button>
            </div>
          ))}
        </div>
      </div>
    ) : null
  );
};

PriceBands.propTypes = {
  priceBands: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default PriceBands;
