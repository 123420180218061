export default (filter) => {
  if ((typeof filter) === 'string') {
    return filter.split('&').reduce((acc, filterItem) => {
      const [dimension, value] = filterItem.split('=');

      return {
        ...acc,
        [dimension]: value,
      };
    }, {});
  }

  return filter;
};
