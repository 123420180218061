import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Outcome from 'Sportsbook/outcomes/views/with_selectors/outcome_in_betslip';
import { makeGetOutcomesIdsInBetslip } from '../selectors';

const OutcomesInBetslip = ({ outcomesIds }) => (
  outcomesIds.length ? (
    <ul className="outcomes-in-betslip">
      {outcomesIds.map((id) => <Outcome id={id} key={id} />)}
    </ul>
  ) : null
);

OutcomesInBetslip.propTypes = {
  outcomesIds: PropTypes.instanceOf(Array).isRequired,
};

const makeMapStateToProps = () => {
  const getOutcomesIdsInBetslip = makeGetOutcomesIdsInBetslip();

  return (state, props) => ({
    outcomesIds: getOutcomesIdsInBetslip(state, props),
  });
};

export default connect(makeMapStateToProps)(OutcomesInBetslip);
