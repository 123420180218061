import PropTypes from 'prop-types';

import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';
import { RacingDefault } from 'Sportsbook/markets/views/racing_default/entry';
import OutrightLtoOutcome from 'Sportsbook/outcomes/views/outright_lto/entry';

export const OutrightLTO = (props) => {
  const { market } = props;

  return (
    <RacingDefault
      {...props}
      outcomeComponent={OutrightLtoOutcome}
      outcomeOptions={market.uiOptions.lengthenTheOdds}
    />
  );
};

OutrightLTO.propTypes = {
  market: PropTypes.instanceOf(Object).isRequired,
};

export default withBackboneMarket(withMarket(OutrightLTO));
