import PropTypes from 'prop-types';
import { pluck } from 'underscore';

const PeriodsTabs = ({ activePeriod, periods, onTabClick }) => (
  periods.length > 1 ? (
    <div className="market-card__periods-tabs">
      {pluck(periods, 'label').map((label, index) => (
        <span className={activePeriod === index ? 'active' : ''} onClick={onTabClick(index)}>
          {label}
        </span>
      ))}
    </div>
  ) : null
);

PeriodsTabs.propTypes = {
  activePeriod: PropTypes.number.isRequired,
  periods: PropTypes.instanceOf(Array).isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default PeriodsTabs;
