import PropTypes from 'prop-types';
import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';
import Outcome from 'Sportsbook/outcomes/views/default/entry';

export const MatchBetting = ({ market }) => (
  <div className="outcomes-row">
    {
      market.o.length === 3 && (
        <>
          <Outcome id={market.o[0]} overridenDesc="1" />
          <Outcome id={market.o[1]} overridenDesc="X" />
          <Outcome id={market.o[2]} overridenDesc="2" />
        </>
      )
    }
  </div>
);

MatchBetting.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.number.isRequired,
    o: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};

export default withBackboneMarket(withMarket(MatchBetting));
