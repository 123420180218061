import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

import { Default as DefaultMarketList } from 'Sportsbook/markets/views/default/entry';
import { withFold } from 'bv-hocs';

const OutcomesList = ({ folded, toggleFolded, market }) => (
  <div className="outcomes-list">
    <Button
      className="outcomes-list__view-more-btn"
      label={t(folded ? 'view_full_list' : 'close_full_list')}
      onClick={toggleFolded}
      secondary
      inverse
    />
    {folded || <DefaultMarketList market={market} />}
  </div>
);

OutcomesList.propTypes = {
  folded: PropTypes.bool.isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  toggleFolded: PropTypes.func.isRequired,
};

export default withFold(OutcomesList);
