import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  findKey, identity, isEqual, partial, property, times,
} from 'underscore';

import { Select } from 'bv-components';
import SelectedOutcome, { SelectedOutcome as FakeOutcome } from 'Sportsbook/outcomes/views/with_selectors/selected_outcome';
import { makeGetDataForSelects } from '../selectors';
import { translateTitles } from '../helpers';

class OutcomesSelectors extends PureComponent {
  constructor(props) {
    super(props);

    this.ref = createRef();
    this.onSelectChange = this.onSelectChange.bind(this);

    this.state = {
      selectedOutcomeId: null,
      selectedValues: [],
    };
  }

  componentDidMount() {
    // Some selectors do not have a blank option (value = "")
    // Trigger a change so the matched outcome will be found
    this.onSelectChange();
  }

  onSelectChange() {
    const { outcomesValues } = this.props;
    const selectedValues = Array.prototype.slice.call(
      this.ref.current.getElementsByTagName('select'),
    ).map(property('value'));

    const selectedOutcomeId = findKey(outcomesValues, partial(isEqual, selectedValues));

    this.setState({
      selectedOutcomeId: Number(selectedOutcomeId),
      selectedValues,
    });
  }

  render() {
    const {
      parseSelectsOptionsFn,
      selectsOptions,
      outcomesValues,
      titles,
      options,
      nSelects,
      selectsDirection,
    } = this.props;

    const {
      selectedValues,
      selectedOutcomeId,
    } = this.state;

    const selections = parseSelectsOptionsFn(selectsOptions, outcomesValues, selectedValues);

    // Not sure if this is the best place, trying to avoid commons in every market with selectors
    // Future: Use directly the component WithSelectors and use the type to extract config
    // Specific components will not exist, just one that will use the market ui to extract the data
    if (titles) {
      translateTitles(options.translations, titles);
    }

    return (
      <div className="flex flex--centered" ref={this.ref}>
        <div className={`market__selects-wrapper market__selects-wrapper--${selectsDirection} flex--grow`}>
          {times(nSelects, (index) => (
            <Select
              includeBlank={titles && titles[index]}
              onChange={this.onSelectChange}
              options={selections[index]}
              value={selectedValues[index]}
            />
          ))}
        </div>

        {selectedOutcomeId
          ? <SelectedOutcome id={selectedOutcomeId} />
          : <FakeOutcome outcome={{ formattedPrice: 'N/A' }} />}
      </div>
    );
  }
}

OutcomesSelectors.propTypes = {
  nSelects: PropTypes.number.isRequired,
  titles: PropTypes.instanceOf(Array).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  selectsOptions: PropTypes.instanceOf(Array).isRequired,
  parseSelectsOptionsFn: PropTypes.func,
  outcomesValues: PropTypes.instanceOf(Object).isRequired,
  selectsDirection: PropTypes.instanceOf(String),
};

OutcomesSelectors.defaultProps = {
  parseSelectsOptionsFn: identity,
  selectsDirection: 'horizontal',
};

export default connect(makeGetDataForSelects)(OutcomesSelectors);
