import PropTypes from 'prop-types';
import { Link } from 'bv-components';
import { useBetBuilder } from 'sportsbook-hooks';
import { t } from 'bv-i18n';

export const PIULink = ({
  outcomeId, eventId, marketTypeId, betBuilder2,
}) => {
  const { supported } = useBetBuilder({ marketTypeId });

  return supported && !betBuilder2
    ? (
      <Link to={`/bet-builder/${eventId}${outcomeId ? `/outcome/${outcomeId}` : ''}`} className="priceitup-event__edit bvs-link bvs-label is-info">
        {t('edit')}
      </Link>
    ) : null;
};

PIULink.propTypes = {
  outcomeId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  marketTypeId: PropTypes.number.isRequired,
  betBuilder2: PropTypes.bool.isRequired,
};

export default PIULink;
