// TODO: It would not be a bad idea to receive this info formatted from BE as well
// Still a good idea as runner info/trainer/form does not change via push notification
import isVirtual from 'Sportsbook/helpers/is_virtual';
import { t } from 'bv-i18n';

const join = (values) => values.filter(Boolean).join(' / ');

const label = (value, translationKey) => (
  value && `${t(translationKey)} : ${value}`
);

const runnerInfo = ({ form, jockey, trainer }) => [
  join([trainer, jockey]),
  label(form, 'form'),
].filter(Boolean);

const dogInfo = ({
  trainerFullName, performances, age, gender,
}) => [
  label(trainerFullName, 'time_form.trainer'),
  join([
    label(performances, 'time_form.performances'),
    label(age, 'time_form.age'),
    gender,
  ]),
].filter(Boolean);

export default (sportEventPathId, outcome, runner) => {
  const { silk, trap } = outcome;
  // Virtuals were coming always with silk true, better not to return that inconsistent value
  // And check here if the sport is virtual, if the sport is virtual and is racing
  // (the helper belongs to racing outcomes) we always show runnerInfo
  // Calling it runner instead of horse as it also applies to virtuals dogs
  if (silk || isVirtual(sportEventPathId)) return runnerInfo(outcome);
  if (trap && runner) return dogInfo(runner);

  return [];
};
