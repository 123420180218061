import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'bv-components';

const eventUrl = ({ sportEventPathId, meetingId, id }) => (
  `/sports/${sportEventPathId}/meetings/${meetingId}/events/${id}`
);

const EventLink = ({
  active, activeRef, onEventClick, description, sportEventPathId, meetingId, id, isTab,
}) => {
  const linkClassNmae = classnames({
    'bvs-button-chip is-small': !isTab,
    'bvs-button-tab': isTab,
    active,
  });

  return (
    <Link
      className={linkClassNmae}
      innerRef={(node) => { if (active) activeRef(node); }}
      to={eventUrl({ sportEventPathId, meetingId, id })}
      onClick={onEventClick}
    >
      {description}
    </Link>
  );
};

EventLink.propTypes = {
  active: PropTypes.bool.isRequired,
  activeRef: PropTypes.func,
  description: PropTypes.string.isRequired,
  onEventClick: PropTypes.func,
  sportEventPathId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  isTab: PropTypes.bool.isRequired,
};

EventLink.defaultProps = {
  activeRef: () => {},
  onEventClick: () => {},
};

export default EventLink;
