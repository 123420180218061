import PropTypes from 'prop-types';

import OutcomesInBetslip from './components/outcomes_in_betslip';
import OutcomesList from './components/outcomes_list';
import OutcomesSelectors from './components/outcomes_selectors';

const WithSelectors = (props) => {
  const { market, ...rest } = props;

  return (
    <div className="market-with-selectors">
      <OutcomesInBetslip outcomesIds={market.o} />
      <OutcomesSelectors marketId={market.id} {...rest} />
      <hr />
      <OutcomesList market={market} />
    </div>
  );
};

WithSelectors.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.number.isRequired,
    o: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
  className: PropTypes.string,
};

WithSelectors.defaultProps = {
  className: '',
};

export default WithSelectors;
