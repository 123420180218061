import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFetch } from 'bv-hooks';

import { withCancellation } from 'Sportsbook/services';
import { apiFetchEvent } from '../api';
import { upsertEvents, removeEvents } from '../duck';

export default (eventId) => {
  const dispatch = useDispatch();

  const cancelableFetchEvent = withCancellation((canceled = { current: false }) => (
    apiFetchEvent({ eventId }).then((event) => {
      if (canceled.current) return undefined;
      if (event) dispatch(upsertEvents([event]));

      return event;
    })
  ));

  const [event, fetching] = useFetch(cancelableFetchEvent.execute, [eventId]);

  // When eventId changes we cancel the fetch in case still in progress
  useEffect(() => cancelableFetchEvent.cancel, [eventId]);

  // When event changes, if there was an event, it is removed from the state
  useEffect(() => () => {
    if (event) dispatch(removeEvents([event.id]));
  }, [event]);

  return [event, fetching];
};
