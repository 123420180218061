import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { withFold } from 'bv-hocs';
import buildPeriodAndSets from './backbone_adapter/build_periods_and_sets';
import Header from './components/header';
import PeriodsTabs from './components/periods_tabs';
import Periods from './components/periods';

const { store } = window.reduxState;

const Layout = ({ hasHeader, children }) => (
  hasHeader
    ? <div className="market-card">{children}</div>
    : children
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasHeader: PropTypes.bool.isRequired,
};

class MarketCard extends PureComponent {
  static getDerivedStateFromProps({ folded }) {
    if (!folded) {
      return {
        everExpanded: true,
      };
    }

    return undefined;
  }

  constructor(props) {
    super(props);

    this.tabClicked = this.tabClicked.bind(this);

    this.state = {
      everExpanded: false,
      activePeriod: 0,
    };
  }

  tabClicked(activePeriod) {
    return () => { this.setState({ activePeriod }); };
  }

  render() {
    const { folded, hasHeader, market } = this.props;
    const { everExpanded, activePeriod } = this.state;
    const parsedMarket = buildPeriodAndSets(market);

    return (
      <Provider store={store}>
        <Layout hasHeader={hasHeader}>
          {hasHeader && <Header {...this.props} market={parsedMarket} />}
          {everExpanded && (
            <div className="bvs-card market-card__content" style={folded ? { display: 'none' } : {}}>
              <PeriodsTabs
                periods={parsedMarket.periods}
                activePeriod={activePeriod}
                onTabClick={this.tabClicked}
              />
              <Periods
                periods={parsedMarket.periods}
                activePeriod={activePeriod}
              />
            </div>
          )}
        </Layout>
      </Provider>
    );
  }
}

MarketCard.propTypes = {
  folded: PropTypes.bool.isRequired,
  hasHeader: PropTypes.bool.isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
};

export default withFold(MarketCard, ({ open }) => !open);
