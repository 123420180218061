import PropTypes from 'prop-types';

import withRunner from 'Sportsbook/outcomes/with_runner';
import Factory from './attributes/factory';

const Stats = ({ runner }) => {
  const attributes = runner ? runner.attributes : [];
  const sliceIndexes = attributes.length <= 4
    ? [attributes.length, 0]
    : [attributes.length - Math.floor(attributes.length / 2), attributes.length];

  const rows = [
    attributes.slice(0, sliceIndexes[0]),
    attributes.slice(sliceIndexes[0], sliceIndexes[1]),
  ];

  return (
    <div className="racecard-modal__runner-details-wrapper">
      <div className="racecard-modal__runner-details-content">
        {
        rows.map((row) => (
          <div className="racecard-modal__runner-details">{ row.map((attribute) => <Factory {...attribute} />) }</div>
        ))
      }
        <div className="racecard-modal__runner-details--comment">{runner.comment}</div>
      </div>
    </div>
  );
};

Stats.propTypes = {
  runner: PropTypes.instanceOf(Object).isRequired,
};

export default withRunner(Stats);
