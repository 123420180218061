import PropTypes from 'prop-types';

import _, { partial, isMatch, noop } from 'underscore';
import withOutcome from 'sportsbook-with-outcome';
import withSmartStat from 'Sportsbook/outcomes/with_smart_stat';
import classnames from 'classnames';
import RunnerDetail from './runner_detail';
import OutcomeButton from './outcome_button';
import SmartStat from './smart_stat/smart_stat';
import SmartStatRibbon from './smart_stat/smart_stat_ribbon';

const OUTCOME_STATUS_SUSPENDED = 2;

const runnerClassName = (disabled) => classnames('racecard-outcome', {
  disabled,
});

const isFavourite = partial(isMatch, _, { desc: '<favourite>' });

// TODO: Should be done on the BE
const parseOutcome = (outcome) => (
  isFavourite(outcome)
    ? { ...outcome, desc: 'FAVOURITE', colour: '20130329fav151506.png' }
    : outcome
);

const Runner = ({
  children,
  outcome,
  toggleBetslip,
  smartStat,
  extendedSmartStat,
  marketDisabled,
  star_rating: starRating,
  displayWeight,
  onClick,
  selected,
  enhancedOutcome,
}) => {
  const parsedOutcome = parseOutcome(outcome);
  const isDisabled = !parsedOutcome.enabled || parsedOutcome.os === OUTCOME_STATUS_SUSPENDED
    || marketDisabled;

  const doToggleBetslip = () => toggleBetslip({
    clickSource: smartStat ? smartStat.betOrigin : '',
  });

  return (
    <>
      <div className={runnerClassName(isDisabled)}>
        <RunnerDetail
          runnerName={parsedOutcome.desc}
          raceNumber={parsedOutcome.raceNumber}
          drawNumber={parsedOutcome.drawNumber}
          jockey={parsedOutcome.jockey}
          trainer={parsedOutcome.trainer}
          form={parsedOutcome.form}
          runnerWeight={parsedOutcome.runnerWeight}
          starRating={starRating}
          silk={parsedOutcome.colour}
          displayWeight={displayWeight}
          onClick={onClick}
          isFavourite={isFavourite(outcome)}
        />
        <OutcomeButton
          outcome={parsedOutcome}
          selected={selected}
          toggleBetslip={doToggleBetslip}
          enhancedOutcome={enhancedOutcome}
        />
      </div>

      {
        smartStat && (
          extendedSmartStat
            ? <SmartStat {...smartStat} />
            : (
              <SmartStatRibbon
                status={smartStat.status}
                ribbonTitle={smartStat.ribbonTitle}
              />
            )
        )
      }

      {children}
    </>
  );
};

Runner.propTypes = {
  outcome: PropTypes.instanceOf(Object).isRequired,
  enhancedOutcome: PropTypes.shape({
    fractionalEnhancedOdds: PropTypes.string.isRequired,
  }).isRequired,
  runner_name: PropTypes.string.isRequired,
  star_rating: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  displayWeight: PropTypes.bool,
  onClick: PropTypes.func,
  smartStat: PropTypes.instanceOf(Object).isRequired,
  extendedSmartStat: PropTypes.bool,
  marketDisabled: PropTypes.bool.isRequired,
};

Runner.defaultProps = {
  displayWeight: false,
  onClick: noop,
  extendedSmartStat: false,
};

export default withOutcome(withSmartStat(Runner));
