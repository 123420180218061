const virtualClassName = (prefix) => (raceNumber) => (
  `outcome-icon virtual-icon ${prefix}-${raceNumber}`
);

const sportEventPathIdToClassNameFn = {
  16334710: virtualClassName('horse-racing'),
  16335010: virtualClassName('motor-racing'),
  16334910: virtualClassName('speedway'),
  34840110: virtualClassName('cycling'),
  144515010: virtualClassName('cycling'),
  16334810: virtualClassName('dog-racing'),
};

export default (sportEventPathId, raceNumber) => {
  const classNameFn = sportEventPathIdToClassNameFn[sportEventPathId];

  return classNameFn ? classNameFn(raceNumber) : null;
};
