import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import PreviousPrice from 'Sportsbook/components/previous_price';
import { formatPrice } from 'sportsbook-helpers';
import { usePriceFormatId } from 'bv-hooks';
import EnhancedPrice from 'Sportsbook/components/enhanced_price';

const buttonClassNames = (selected) => classnames('bvs-button-sport racecard-outcome-button', {
  active: selected,
});

const OutcomeButton = ({
  outcome, selected, toggleBetslip, enhancedOutcome,
}) => {
  const priceFormatId = usePriceFormatId();
  return (
    <div className="racecard-outcome-button-wrapper">
      <div className={buttonClassNames(selected)} onClick={toggleBetslip}>
        {
          enhancedOutcome && enhancedOutcome.fractionalEnhancedOdds
            ? (
              <EnhancedPrice
                enhancedPrice={formatPrice(enhancedOutcome.fractionalEnhancedOdds, priceFormatId)}
                oldPrice={outcome.formattedPrice}
              />
            )
            : <span>{outcome.enabled ? outcome.formattedPrice : t('nr')}</span>
        }
      </div>
      {outcome.enabled && (
        <PreviousPrice
          previousPrice={outcome.pp}
          antePreviousPrice={outcome.app}
        />
      )}
    </div>
  );
};

OutcomeButton.propTypes = {
  outcome: PropTypes.shape({
    formatPrice: PropTypes.func,
    formattedPrice: PropTypes.string,
    enabled: PropTypes.bool,
    pp: PropTypes.string,
    app: PropTypes.string,
  }).isRequired,
  enhancedOutcome: PropTypes.shape({
    fractionalEnhancedOdds: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
};

export default OutcomeButton;
/* eslint jsx-a11y/no-static-element-interactions: off */
