import PropTypes from 'prop-types';
import { dasherize } from 'underscore.string';

const toClassName = (string) => dasherize(string.toLowerCase());

const EventIcon = ({ badge }) => (
  <span className={`bvs-event-icon is-${toClassName(badge)}`} />
);

EventIcon.propTypes = {
  badge: PropTypes.string.isRequired,
};

export default EventIcon;
