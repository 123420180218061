import PropTypes from 'prop-types';

import { Icon } from 'bv-components';
import { withFeature } from 'bv-hocs';

const CashoutIcons = ({ featureEnabled, market }) => (
  featureEnabled ? [
    (market.cashoutEligible || market.cashoutable)
    && (
    <Icon
      id="cashout"
      type="sportsbook-cashout-icons"
    />
    ),
    market.goesInPlay && <Icon id="watch-light" />,
  ] : null
);

CashoutIcons.propTypes = {
  featureEnabled: PropTypes.bool.isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
};

export default withFeature('cashout')(CashoutIcons);

// cashoutEligible is not in the redux state so as it's a new name in redux state
// Better to call it cashoutable same as in event service backend
// Event level is naming it ca, to be unified to cashoutable everywhere
