import { decimalPrice } from 'bv';

// Comparator used to sort outcomes by price
const priceComparator = (a, b) => a.prd - b.prd;

// Return actual price band values (decimal min and max prices for the band)
// given our server representation that could contain > or < signs.
const bandLimits = (priceBand) => {
  if (priceBand[0] === '<') {
    return [
      null,
      decimalPrice(priceBand[1]),
    ];
  }

  if (priceBand[0] === '>' || priceBand[1] === '>') {
    return [
      decimalPrice(priceBand[0] === '>' ? priceBand[1] : priceBand[0]),
      1000000000,
    ];
  }

  return [
    decimalPrice(priceBand[0]),
    decimalPrice(priceBand[1]),
  ];
};

// Check if a given outcome is included into a price band
const outcomeInBand = (priceBand) => (outcome) => {
  const activeBandLimits = bandLimits(priceBand);

  return outcome.prd >= activeBandLimits[0] && outcome.prd < activeBandLimits[1];
};

const bandOutcomes = (outcomes, priceBand) => {
  if (!priceBand) return outcomes;

  return outcomes
    .slice()
    .sort(priceComparator)
    .filter(outcomeInBand(priceBand));
};

export const getPriceBandsWithOutcomes = (outcomes, priceBands) => (
  priceBands.filter(((priceBand) => bandOutcomes(outcomes, priceBand).length > 0))
);

export default bandOutcomes;
