import PropTypes from 'prop-types';

import Rating from './rating';
import TitleValue from './title_value';
import Title from './title';

const Factory = (props) => {
  const { type } = props;

  switch (type) {
    case 'rating':
      return <Rating {...props} />;
    case 'title/value':
      return <TitleValue {...props} />;
    default:
      return <Title {...props} />;
  }
};

Factory.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Factory;
