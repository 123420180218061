import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';

import WithSelectors from '../with_selectors/entry';

// Match separated by :, / or -
// BE validates that only one - exists in the string
const splitFn = (desc) => desc.match(/(.*)\s[/|\-|:]\s(.*)/)?.splice(1, 3);

const TwoSelectHalfTimeFullTime = (props) => (
  <WithSelectors
    {...props}
    splitFn={splitFn}
    nSelects={2}
  />
);

export default withBackboneMarket(withMarket(TwoSelectHalfTimeFullTime));
