import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeGetSmartStat } from './selector';

export default (WrappedComponent) => {
  const makeMapStateToProps = () => {
    const getSmartStat = makeGetSmartStat();

    return (state, ownProps) => ({
      smartStat: getSmartStat(state, ownProps),
    });
  };

  const WithSmartStat = (props) => <WrappedComponent {...props} />;

  WithSmartStat.propTypes = {
    id: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    smartStat: PropTypes.instanceOf(Object).isRequired,
  };

  WithSmartStat.displayName = 'withSmartStat';

  return connect(makeMapStateToProps)(WithSmartStat);
};
