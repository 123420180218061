import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import marketMapping from 'Sportsbook/markets/views/entry';

class Period extends PureComponent {
  static getDerivedStateFromProps(props) {
    if (props.active) {
      return {
        everActive: true,
      };
    }

    return undefined;
  }

  constructor(props) {
    super(props);

    this.state = {
      everActive: false,
    };
  }

  render() {
    const { everActive } = this.state;

    if (!everActive) return null;

    const { active, period } = this.props;

    const mapping = marketMapping(period.sportId);
    const Market = mapping[period.ui] || mapping.Default;

    return (
      <div className="market-card__market-view" style={active ? {} : { display: 'none' }}>
        {period.marketGroups.map((market) => (
          <Market backboneMarket={market} />
        ))}
      </div>
    );
  }
}

Period.propTypes = {
  active: PropTypes.bool.isRequired,
  period: PropTypes.instanceOf(Object).isRequired,
};

export default Period;
