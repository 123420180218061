import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import { Carousel } from 'bv-components';

import EventsList from '../events_list/events_list';

class EventsSlider extends PureComponent {
  constructor(props) {
    super(props);

    this.sliderRef = createRef();
    this.scrollToEvent = this.scrollToEvent.bind(this);
  }

  componentDidMount() {
    this.scrollToEvent();
  }

  componentDidUpdate() {
    this.scrollToEvent();
  }

  scrollToEvent() {
    if (this.activeRef && this.sliderRef.current) {
      this.sliderRef.current.scrollLeft = this.activeRef.offsetLeft - 12;
    }
  }

  render() {
    const { eventId, events } = this.props;

    return (
      <div className="js-before-player-stream" ref={this.sliderRef}>
        <Carousel
          embedded
          scrollStep={234}
          className="btns btns--slider"
        >
          <EventsList
            activeRef={(ref) => { this.activeRef = ref; this.scrollToEvent(); }}
            activeEventId={eventId}
            events={events}
            isTab
          />
        </Carousel>
      </div>
    );
  }
}

EventsSlider.propTypes = {
  eventId: PropTypes.number.isRequired,
  events: PropTypes.instanceOf(Array).isRequired,
};

export default EventsSlider;
