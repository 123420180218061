import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OPEN_BETS_TAB_ID } from 'MarketsV2/helpers/constants';
import { OpenBets } from 'SharedComponents/my_bets/components';
import Context from 'Sportsbook/context';
import MarketCard from './components/market_card';
import MarketPicker from './components/market_picker/market_picker';

const MarketsView = ({
  sportEventPathId,
  marketsIds,
  open,
  picker,
  eventId,
}) => {
  const [activeMarketId, setActiveMarketId] = useState(marketsIds[0]);
  const sportEventPathIdValue = useMemo(() => ({ sportEventPathId }), [sportEventPathId]);

  useEffect(() => {
    if (!marketsIds.includes(activeMarketId) && marketsIds.length
      && activeMarketId !== OPEN_BETS_TAB_ID) {
      setActiveMarketId(marketsIds[0]);
    }
  }, [activeMarketId, marketsIds]);

  return (
    <Context.Provider value={sportEventPathIdValue}>
      {picker ? (
        <>
          <MarketPicker
            marketsIds={marketsIds}
            activeMarketId={activeMarketId}
            setActiveMarketId={setActiveMarketId}
            eventId={eventId}
          />
          {activeMarketId === OPEN_BETS_TAB_ID
            ? <OpenBets eventId={eventId} />
            : <MarketCard id={activeMarketId} eventId={eventId} open />}
        </>
      ) : (
        marketsIds.map((id, index) => (
          <MarketCard id={id} open={index < open} />
        ))
      )}
    </Context.Provider>
  );
};

MarketsView.propTypes = {
  sportEventPathId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  marketsIds: PropTypes.instanceOf(Array).isRequired,
  open: PropTypes.number.isRequired,
  picker: PropTypes.bool.isRequired,
};

export default MarketsView;
