export default (markets) => (
  markets.map((market) => ({
    id: market.id,
    des: market.description,
    eId: market.eventId,
    ew: market.eachWay,
    st: market.status,
    pltD: market.placeTermsDeduction,
    pltDes: market.placeTermsDescription,
    p: market.periodDescription,
    mtId: market.marketTypeId,
    cashoutable: market.cashoutable,
    ui: market.ui,
    uiOptions: market.uiOptions,
    o: market.outcomes.map((outcome) => ({
      id: outcome.id,
      desc: outcome.description,
      prid: outcome.priceId,
      prd: outcome.price,
      pr: outcome.formattedPrice,
      form: outcome.form,
      jockey: outcome.jockey,
      raceNumber: outcome.raceCardNumber,
      trainer: outcome.trainer,
      hidden: outcome.hidden,
      key: outcome.key,
      keyDimension: outcome.keyDimension,
      colour: outcome.colour,
      pp: outcome.formattedPenultimatePrice,
      app: outcome.formattedAntepenultimatePrice,
      opponentId: outcome.opponentId,
      spread: outcome.extraKey1,
      runnerWeight: outcome.runnerWeight,
      drawNumber: outcome.drawNumber,
      // Former enabled is just a field calculated from withdrawn and balloted_out
      // It used to come calculated from backend but in it should not
      // An outcome could get withdrawn via push notification
      // When outcome is updated via push notification enabled should be updated too
      // Either that or apply the logic (!withdrawn) at component level
      enabled: !outcome.withdrawn,
      // Needed in order to get the market from an outcome, not sure we should do that
      // Provide the market as context for example
      mId: market.id,
      // Boost Fields
      wasPrice: outcome.wasPrice,
      formattedWasPrice: outcome.formattedWasPrice,
      os: outcome.status,
    })),
  }))
);
