import { isEqual, omit } from 'underscore';
/* eslint no-case-declarations:"off" */

// Action types
export const ADD_MARKETS = 'sportsbook/markets/ADD_MARKETS';
export const UPDATE_MARKET = 'sportsbook/markets/UPDATE_MARKET';
export const UPSERT_MARKETS = 'sportsbook/markets/UPSERT_MARKETS';
export const REMOVE_MARKETS = 'sportsbook/markets/REMOVE_MARKETS';

const initialState = {};

// Action creators
export const addMarkets = (markets) => ({
  type: ADD_MARKETS,
  markets,
});

export const updateMarket = (marketId, attributes) => ({
  type: UPDATE_MARKET,
  marketId,
  attributes: omit(attributes, ['pltDes']),
});

export const upsertMarkets = (markets) => ({
  type: UPSERT_MARKETS,
  markets: markets.map((m) => omit(m, ['pltDes'])),
});

export const removeMarkets = (marketIds) => ({
  type: REMOVE_MARKETS,
  marketIds,
});

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_MARKETS:
    case UPSERT_MARKETS:
      return {
        ...state,
        ...action.markets.reduce((acc, market) => {
          const currentMarket = state[market.id];
          const currentMarketRefCount = (currentMarket && currentMarket.refCount) || 0;
          const isUpdateOlder = (currentMarket && currentMarket.timestamp > market.timestamp);

          let newMarket = null;
          if (action.type === UPSERT_MARKETS) {
            // Sometimes the upsert we try to apply are outdated
            if (isUpdateOlder) {
              newMarket = {
                ...market,
                ...currentMarket,
                refCount: Math.max(currentMarketRefCount, 1),
              };
            } else {
              newMarket = {
                ...currentMarket,
                ...market,
                refCount: Math.max(currentMarketRefCount, 1),
              };
            }
          } else if (isUpdateOlder) {
            newMarket = {
              ...market,
              ...currentMarket,
              refCount: currentMarketRefCount + 1,
            };
          } else {
            newMarket = {
              ...currentMarket,
              ...market,
              refCount: currentMarketRefCount + 1,
            };
          }

          acc[market.id] = currentMarket && isEqual(currentMarket, newMarket)
            ? currentMarket
            : newMarket;

          return acc;
        }, {}),
      };

    case UPDATE_MARKET:
      const oldMarket = state[action.marketId];
      const update = {};

      // if the new attributes' timestamp is older than the current one, skip the update
      if (
        oldMarket && (oldMarket.timestamp || 0)
        <= (action.attributes.timestamp || (new Date()).getTime())
      ) {
        update[action.marketId] = { ...oldMarket, ...action.attributes };
      }
      return { ...state, ...update };

    case REMOVE_MARKETS:
      // Cant use map(parseInt) directly:
      // https://stackoverflow.com/questions/14528397/strange-behavior-for-map-parseint
      const marketIds = action.marketIds.map((id) => parseInt(id, 10));
      return Object.keys(state).map((el) => parseInt(el, 10)).reduce((acc, marketId) => {
        const market = state[marketId];

        // If the market is not one of the ones we want to remove
        // we will add it to the acc without any change
        if (!marketIds.includes(marketId)) {
          return {
            ...acc,
            [marketId]: market,
          };
        }

        // If the market wants to be removed and it is the last ref count
        // we wont add it to the acc
        if (market.refCount === 1) {
          return acc;
        }

        // In any other case add the market to the acc decreading refCount by 1
        return {
          ...acc,
          [marketId]: {
            ...market,
            refCount: market.refCount - 1,
          },
        };
      }, {});

    default:
      return state;
  }
};
