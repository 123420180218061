/* eslint import/no-named-as-default: off */
import PropTypes from 'prop-types';
import withOutcome from 'Sportsbook/outcomes/with_outcome';
import classnames from 'classnames';
import PIULink from './components/piu_link';

export const Outcome = ({
  selected, disabled, id, outcome, liveEvent, eventId, marketTypeId, toggleBetslip, betBuilder2,
}) => {
  const priceBtnClassnames = classnames('bvs-button-sport priceitup-price-button is-square', {
    active: selected,
    disabled,
  });

  const bb2PriceBtnClassnames = classnames('bvs-button-sport', {
    active: selected,
    disabled,
  });

  return (
    <div id={`outcome-${id}`} className="priceitup-event bvs-card">
      <ul className="priceitup-event__list">
        {(outcome.legDescriptions || [outcome.desc]).map((legDescription) => (
          <li key={legDescription}>{legDescription}</li>
        ))}
      </ul>
      {!liveEvent && (
        <PIULink
          outcomeId={id}
          eventId={eventId}
          marketTypeId={marketTypeId}
          betBuilder2={betBuilder2}
        />
      )}
      {betBuilder2 ? (
        <div className="bb2-piu-price-button-wrapper">
          <button
            type="button"
            className={bb2PriceBtnClassnames}
            onClick={toggleBetslip}
          >
            {outcome.formattedPrice}
          </button>
        </div>
      ) : (
        <div className="priceitup-price-button-wrapper">
          <button
            type="button"
            className={priceBtnClassnames}
            onClick={toggleBetslip}
          >
            {outcome.formattedPrice}
          </button>
        </div>
      )}
    </div>
  );
};

Outcome.propTypes = {
  id: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  marketTypeId: PropTypes.number.isRequired,
  outcome: PropTypes.shape({
    desc: PropTypes.string.isRequired,
    legDescriptions: PropTypes.instanceOf(Array).isRequired,
    formattedPrice: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  liveEvent: PropTypes.bool,
  disabled: PropTypes.bool,
  betBuilder2: PropTypes.bool,
};

Outcome.defaultProps = {
  liveEvent: false,
  disabled: false,
  betBuilder2: false,
};

export default withOutcome(Outcome);
