import { useFetch } from 'bv-hooks';
import fetchSportsTree from '../api/fetch_sports_tree';

export default ({
  structure, filter, includeEvents, segregateEventTypes, refreshProps = [],
}) => (
  useFetch(
    () => fetchSportsTree({
      structure,
      filter,
      includeEvents,
      segregateEventTypes,
    }), refreshProps,
  )
);
