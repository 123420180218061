import PropTypes from 'prop-types';
import factory from './factory';

const HeaderSubtitle = ({ eventId, sportEventPathId }) => {
  const Component = factory(sportEventPathId);

  return Component && <Component eventId={eventId} className="bvs-h5 market-description" />;
};

HeaderSubtitle.propTypes = {
  eventId: PropTypes.number.isRequired,
  sportEventPathId: PropTypes.number.isRequired,
};

export default HeaderSubtitle;
