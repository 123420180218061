export default (cancelableFn) => {
  const canceled = {
    current: false,
  };

  return {
    execute: (extra = {}) => cancelableFn(canceled, extra),
    cancel: () => { canceled.current = true; },
  };
};
