import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';

import WithSelectors from '../with_selectors/entry';

const splitFn = (desc) => desc.match(/(.*[^\s])\s*[/|-]\s*(.*[^\s])\s*[/|-]\s*(.*[^\s])/)?.slice(1, 4);

const GenericThreeSelectors = (props) => (
  <WithSelectors
    {...props}
    splitFn={splitFn}
    nSelects={3}
    titles={[t('match_result'), t('total_goals'), t('anytime_goalscorer')]}
    selectsDirection="vertical"
  />
);

GenericThreeSelectors.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
};

export default withBackboneMarket(withMarket(GenericThreeSelectors));
