import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const OutcomesDisclaimer = ({
  currentBandOutcomesCount,
  outcomesCount,
}) => {
  if (currentBandOutcomesCount === outcomesCount) return null;

  return (
    <div>
      <p className="priceitup-message">
        {t('javascript.price_it_up.outcomes_count', { outcomes: currentBandOutcomesCount, outcomesTotal: outcomesCount })}
      </p>
      {
        currentBandOutcomesCount === 0
        && (
        <p className="priceitup-message">
          {t('javascript.price_it_up.no-results')}
        </p>
        )
      }
    </div>
  );
};

OutcomesDisclaimer.propTypes = {
  currentBandOutcomesCount: PropTypes.number.isRequired,
  outcomesCount: PropTypes.number.isRequired,
};

export default OutcomesDisclaimer;
