import PropTypes from 'prop-types';

import withOutcome from 'Sportsbook/outcomes/with_outcome';
import { Outcome as DefaultOutcome } from 'Sportsbook/outcomes/views/default/entry';
import parseDescription from './helpers/parse_description';

export const Outcome = (props) => {
  const { outcome } = props;

  return (
    <DefaultOutcome
      {...props}
      overridenDesc={parseDescription(outcome.desc)}
    />
  );
};

Outcome.propTypes = {
  outcome: PropTypes.instanceOf(Object).isRequired,
};

export default withOutcome(Outcome);
