import PropTypes from 'prop-types';

import { StarRating } from 'bv-components';

const Rating = ({ title, value }) => (
  <div className="racecard-modal__runner-details--title">
    <strong>{title}</strong>
    {' '}
    <StarRating rating={value} />
  </div>
);

Rating.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default Rating;
