import base from './base';
import priceItUpOptions from './price_it_up';
import runnersData from './runners_data';

/*

  On this module we will be extracting some parameters from the old backend market
  that are presentational related so we can provide them independently from
  the sportsbook data (market, outcome, ...) into the components

  Initially we created a mapping per market type, so we were just injecting
  the options when the market type needed them, however this made this market views
  non generic (we could not reuse a market view with some options in two different
  market types without adding its ids to the options mapping) so we decided
  to remove it

  What we ideally want is not to have something like this. This options are
  tied to the market view that has been selected in the CMS and we want to present
  so they should come from the backend in the format we want (right now it's not good
  because they come all mixed with the sportsbook data and is difficult to discern
  which they are)

*/

export default (market) => ({
  ...base(market),
  ...priceItUpOptions(market),
  // TODO: Just needed for Greyhounds that are still fetching timeForm as part of the market
  ...runnersData(market),
  // ----------------------------------------------------------------------------------------
});
