import PropTypes from 'prop-types';
import { Card } from 'bv-components';

import EventsList from '../events_list/events_list';

const EventsTreePicker = ({ tree, eventId, onEventClick }) => (
  <Card className="navbar-modal">
    {tree.map(({ children, description }) => (
      <div className="navbar-modal__market">
        <h5 className="navbar-modal__market-title">{description}</h5>
        <EventsList
          activeEventId={eventId}
          events={children}
          onEventClick={onEventClick}
        />
      </div>
    ))}
  </Card>
);

EventsTreePicker.propTypes = {
  eventId: PropTypes.number.isRequired,
  onEventClick: PropTypes.func.isRequired,
  tree: PropTypes.instanceOf(Array).isRequired,
};

export default EventsTreePicker;
