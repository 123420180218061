import {
  FETCH_SUSPENSION_REASONS_SUCCESS,
} from './action_types';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SUSPENSION_REASONS_SUCCESS:
      return action.suspensionReasons;

    default:
      return state;
  }
};
