import getIsHorseRacing from 'Sportsbook/helpers/is_horse_racing';
import getIsVirtual from 'Sportsbook/helpers/is_virtual';

export default (sportEventPathId, event, market) => {
  const isHorseRacing = getIsHorseRacing(sportEventPathId);
  const isVirtual = getIsVirtual(sportEventPathId);

  return [
    market.des,
    !isHorseRacing && market.p,
    isHorseRacing && event && !event.timeForm && event.eventInformation,
    !isVirtual && market.ew && market.pltD !== 1 && market.pltDes, // Add showExtraInfo check
  ].filter(Boolean).join(' - ');
};

// Note:
// Instead of using sport show extra info
// I am implementing based in the current state of the db
// horse racing is always configured to show extra info.
// all sports with EW except virtuals are always configured not to show extra info.
