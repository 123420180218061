import { locale as getLocale } from 'bv-i18n';

export const translateTitles = (translations, defaultValues) => {
  const locale = getLocale();
  const shortLocale = locale.split('-')[0];

  const localeTranslations = translations[locale] || translations[shortLocale];

  if (!localeTranslations) return defaultValues;

  return defaultValues.map((value, index) => (
    localeTranslations[index] || value
  ));
};

export default {
  translateTitles,
};
