import { registerHandler, unregisterHandler } from 'event-bus';
import { v as bvVar } from 'bv';
import {
  updateOutcome, ADD_OUTCOMES, UPSERT_OUTCOMES, REMOVE_OUTCOMES,
} from './duck';

const outcomeUpdateHandler = (_, { body: data }) => {
  const { store } = window.reduxState;

  // pcatalog field <= mao field
  const outcome = { ...data, hidden: data.h };
  if (outcome.po) outcome.op = outcome.po;

  store.dispatch(updateOutcome(outcome.oid, outcome));
};

const outcomesMiddleware = (store) => (next) => (action) => {
  let outcomes;
  let outcomeIds;
  const brandId = bvVar('brandId');

  switch (action.type) {
    case ADD_OUTCOMES:
    case UPSERT_OUTCOMES:
      outcomes = store.getState().outcomes || {};

      (action.outcomes || []).forEach((outcome) => {
        if (!outcomes[outcome.id]) {
          registerHandler(`/outcome/${outcome.id}/brand/${brandId}`, outcomeUpdateHandler);
        }
      });
      break;

    case REMOVE_OUTCOMES:
      outcomes = store.getState().outcomes || {};
      outcomeIds = (action.outcomeIds || []).map((id) => parseInt(id, 10));
      Object.keys(outcomes).map((el) => parseInt(el, 10)).forEach((outcomeId) => {
        const outcome = outcomes[outcomeId];

        // If the outcome in question will be removed (before remove it's refCount === 1,
        // we unsubscribe from it
        if (outcomeIds.includes(outcomeId) && outcome && outcome.refCount === 1) {
          unregisterHandler(`/outcome/${outcome.id}/brand/${brandId}`, outcomeUpdateHandler);
        }
      });
      break;

    default:
      break;
  }

  next(action);
};

export default outcomesMiddleware;
