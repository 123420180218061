import {
  DefaultComponent,
  RacingDefaultComponent,
  OutrightLTOComponent,
  BetBoost,
} from 'Sportsbook/markets/views/market_views';

const supportedMarketsUis = {
  Default: DefaultComponent,
  RacingDefault: RacingDefaultComponent,
  OutrightLengthenTheOdds: OutrightLTOComponent,
  BetBoost,
};

export default (ui) => (
  supportedMarketsUis[ui] || DefaultComponent
);
