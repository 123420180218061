import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ListModal, DotsIcon } from 'bv-components';
import { useToggle } from 'bv-hooks';

const MoreMarkets = ({
  setActiveMarketId,
  markets,
}) => {
  const [expanded, toggleExpanded] = useToggle();

  const onMarketClick = (market) => {
    setActiveMarketId(market.id);
    toggleExpanded();
  };

  return (
    <>
      <DotsIcon onDotsClick={toggleExpanded} className="listmodal-opening-button" />
      {expanded && (
        <ListModal
          onCloseClick={toggleExpanded}
          items={markets}
          idAttribute="id"
          descriptionAttribute="des"
          onItemClick={onMarketClick}
        />
      )}
    </>
  );
};

MoreMarkets.propTypes = {
  markets: PropTypes.instanceOf(Array).isRequired,
  setActiveMarketId: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  markets: props.marketsIds.map((marketId) => state.markets[marketId]),
});

export default connect(mapStateToProps)(MoreMarkets);
