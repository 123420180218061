import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import withBackboneMarket from 'Sportsbook/markets/backbone_adapter/with_backbone_market';
import withMarket from 'Sportsbook/markets/with_market';

import WithSelectors from '../with_selectors/entry';
import parseSelectsOptionsFn from './parser';

const splitFn = (desc) => desc.match(/(.*)\s-\s(.*)/)?.slice(1, 3);

const selectorTitles = ({ firstSelectorLabel, secondSelectorLabel }) => ([
  firstSelectorLabel || t('select_player'),
  secondSelectorLabel || t('select_score'),
]);

export const Scorecast = (props) => {
  const { options } = props;

  return (
    <WithSelectors
      {...props}
      splitFn={splitFn}
      nSelects={2}
      titles={selectorTitles(options)}
      parseSelectsOptionsFn={parseSelectsOptionsFn}
    />
  );
};

Scorecast.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
};

export default withBackboneMarket(withMarket(Scorecast));
