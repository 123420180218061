import PropTypes from 'prop-types';
import { assetPath } from 'bv';

const { missingSilkImageUrl } = window;

const Silk = ({ silk }) => (silk ? (
  <img
    className="outcome-icon outcome-icon--silk"
    alt=""
    src={assetPath(`/silks/${silk}`)}
    onError={(e) => { e.target.src = missingSilkImageUrl; }}
  />
) : null);

Silk.propTypes = {
  silk: PropTypes.string.isRequired,
};

export default Silk;
